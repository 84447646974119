import React from "react";

interface Props {
  submit: boolean;
  text: string;
  classString?: string;
  action?: any;
}

const ProjectButton: React.FC<Props> = ({ submit, text, classString, action }) => {
  return (
    <div className={`flex ${classString}`}>
      <button
        type={`${submit}` ? "submit" : "button"}
        className="small-project-button"
        onClick={action}
      >
        {text}
      </button>
    </div>
  );
};

export default ProjectButton;
