import React from "react";

import SuggestionList from "../../../FormInputFields/SuggestionList";
import SuggestionListWrapper from "../../../FormInputFields/SuggestionListWrapper";

const AppealSuggestions: React.FC = () => {
  const SuggestionListText: [string, string][] = [
    [
      "私は日本で働くのが夢で、日本語を勉強していました。",
      "I have studied Japanese previously because my dream is to work in Japan.",
    ],
    [
      "この仕事は日本で経験があります。なので活かせるところがあると思います。",
      "I have experience with this type of work in Japan, so I think I can contribute to this work.",
    ],
    ["私はこの仕事に興味があります。", "I am very interested in this work."],
    ["とてもやる気があります。", "I am very motivated."],
    [
      "精一杯頑張りますので、宜しくお願いします。",
      "I will do my best. Thank you for your consideration.",
    ],
    [
      "営業経験を活かして、 [仕事の分野] の仕事にて活躍したい",
      "I would like to work a job in the field of [field of work].",
    ],
  ];

  return (
    <>
      <SuggestionListWrapper>
        {SuggestionListText.map((strings, index) =>
          strings ? (
            <SuggestionList
              key={index}
              JPtext={strings[0]}
              ENtext={strings[1]}
            />
          ) : (
            ""
          )
        )}
      </SuggestionListWrapper>
    </>
  );
};

export default AppealSuggestions;
