import React, { useContext } from "react";
import { AppContext } from "../../AppState/AppState";

const SuggestionListWrapper: React.FC = ({ children }) => {
  const {language, USF} = useContext(AppContext);

  return (
    <>
      <h2 className="h2 pt-2">{language === USF ? "Suggestion List" : "おすすめのリスト"}</h2>
      <div className="grid place-content-center px-4">
        <div className="w-full">{children}</div>
      </div>
    </>
  );
};

export default SuggestionListWrapper;
