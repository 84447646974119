import React from "react";

interface Props {
  title: string;
  extraClass?: string;
  variable: any;
}

const HalfBox: React.FC<Props> = ({title, extraClass, variable}) => {
  return (
    <>
      <div className="w-full border-b-2 h-full">
        <div className={`w-full grid place-content-center h-full ${extraClass}`}>{title}</div>
        <div className={`w-full grid grid-flow-col place-content-center ${extraClass} h-[112%]`}>
          <span
            className={`${variable ? "border-[1px] rounded-full p-1" : "p-1"}`}
          >
            有
          </span>
          <span className="px-2 py-1">・</span>
          <span
            className={`${!variable ? "border-[1px] rounded-full p-1" : "p-1"}`}
          >
            無
          </span>
        </div>
      </div>
    </>
  );
};

export default HalfBox;
