import React from "react";

import InputTitle from "./InputTitle";

interface Props {
  title?: string;
  label?: string;
  name: string;
  max: number;
  place: string;
  change: any;
  varOnly: boolean;
  classString?: string;
}

const TextArea: React.FC<Props> = ({
  title,
  label,
  name,
  max,
  place,
  change,
  varOnly,
  classString,
}) => {
  
  return (
      <fieldset className="section">
        <div className="input-div grid place-content-center">
          <InputTitle fieldName={title ? title : ""} />
          <label className="text-purple" htmlFor={name}>{label}:</label>
          <textarea
            wrap="hard"
            className={`text-area ${classString}`}
            name={name}
            maxLength={max}
            placeholder={place}
            onChange={varOnly ? change : (e) => change(e.target.value)}
          />
        </div>
      </fieldset>
  );
};

export default TextArea;
