import React from "react";

const ResumeHeader: React.FC = () => {

  const d = new Date();
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const date = d.getDate();
  
  return (
    <>
      <section className="flex justify-between w-2/3 ml-[5%] pt-5">
        <p className="justify-start pr-14">履歴書</p>
        <p className="justify-end">
          令和　{year - 2018}年{month}月{date}日 現在
        </p>
      </section>
    </>
  );
};

export default ResumeHeader;
