import React, { useContext } from "react";

import FormInputWrapper from "../../../FormInputFields/FormInputWrapper";
import TextInput from "../../../FormInputFields/TextInput";
import DataList from "../../../FormInputFields/DataList";
import DoubleDate from "../../../FormInputFields/DoubleDate";

import EducationList from "./EducationList";
import WorkList from "./WorkList";

import SmallCircleButton from "../../../Buttons/SmallCircleButton";
import NextButton from "../../../Buttons/NextButton";
import BackButton from "../../../Buttons/BackButton";

import { Form2Context } from "../../ResumeState/Form2State";
import { AppContext } from "../../../../AppState/AppState";

const Form2: React.FC = () => {
  const {language, USF} = useContext(AppContext);

  const {
    //Education
    setEducStartDate,
    setEducEndDate,
    major,
    setMajor,
    result,
    setResult,
    department,
    setDepartment,
    schoolName,
    setSchoolName,
    educHist,
    setEducHist,
    //Work
    companyName,
    setCompanyName,
    startExpl,
    setStartExpl,
    endExpl,
    setEndExpl,
    setWorkStartDate,
    setWorkEndDate,
    workHist,
    setWorkHist,
  } = useContext(Form2Context);

  const handleEducAdd = (e: React.FormEvent) => {
    e.preventDefault();
    if (schoolName) {
      setEducHist(() => {});
      setSchoolName("");
      setDepartment("");
      setMajor("");
      setResult("");
    }
  };

  const handleWorkAdd = (e: React.FormEvent) => {
    e.preventDefault();
    if (companyName) {
      setWorkHist(() => {});
      setCompanyName("");
      setStartExpl("");
      setEndExpl("");
    }
  };

  const onEducSubmitHandler = (e: any) => {
    e.preventDefault();
    handleEducAdd(e);
  };

  const onWorkSubmitHandler = (e: any) => {
    e.preventDefault();
    handleWorkAdd(e);
  };

  const US = {
    educBG: "Educational Background",
    school: "Name of School",
    department: "Department",
    major: "Major",
    result: "Result",
    workBG: "Work History",
    companyName: "Company/Organization Name",
    startExpl: "Explanation for Starting Work",
    endExpl: "Explanation for Leaving Work",
  }
  const JP = {
    educBG: "学歴",
    school: "学校の名前",
    department: "学部",
    major: "専門・専攻",
    result: "結構",
    workBG: "職歴",
    companyName: "会社の名前",
    startExpl: "仕事を始める説明",
    endExpl: "仕事の引退する説明",
  }

  let lang = US;
  language === USF ? (lang = US) : (lang = JP);
  return (
    <>
      <div className="w-full xl:w-1/2 grid md:flex md:gap-10 xl:grid place-content-center">
        <form onSubmit={onEducSubmitHandler}>
          <FormInputWrapper
            classString="section md:order-1 md:row-span-2 md:row-start-1"
            label={lang.educBG}
          >
            <TextInput
              max={25}
              place="東京大学院"
              label={lang.school}
              name="schoolName"
              value={schoolName}
              change={setSchoolName}
              varOnly={false}
            />
            <TextInput
              max={15}
              place="法学政治学研究"
              label={lang.department}
              value={department}
              change={setDepartment}
              varOnly={false}
            />
            <TextInput
              max={10}
              place="法学部"
              label={lang.major}
              value={major}
              change={setMajor}
              varOnly={false}
            />
            <DataList
              name="educationResult"
              label={lang.result}
              max={4}
              value={result}
              change={setResult}
              varOnly={false}
              listName="results"
              optionList={["在学", "卒業", "他の"]}
            />
            <DoubleDate
              change1={setEducStartDate}
              change2={setEducEndDate}
              varOnly={false}
            />
          </FormInputWrapper>
          {educHist.length < 5 ? <SmallCircleButton /> : ""}
          <EducationList educHist={educHist} />
        </form>
        <form onSubmit={onWorkSubmitHandler}>
          <FormInputWrapper
            classString="section md:order-1 md:row-span-2 md:row-start-1"
            label={lang.workBG}
          >
            <TextInput
              label={lang.companyName}
              max={30}
              place="アマゾン株式会社"
              value={companyName}
              change={setCompanyName}
              varOnly={false}
            />
            <DataList
              value={startExpl}
              change={setStartExpl}
              varOnly={false}
              max={10}
              name="workStart"
              label={lang.startExpl}
              listName="starting"
              optionList={["入社", "契約期間の初め", "他の"]}
            />
            <DataList
              label={lang.endExpl}
              name="workLeave"
              value={endExpl}
              change={setEndExpl}
              varOnly={false}
              max={12}
              listName="ending"
              optionList={[
                "現在に至る",
                "一身上の都合により退職",
                "契約期間満了につき退社",
                "会社都合により退社",
                "結婚のために",
                "出産のために",
                "家族の介護のため",
                "病院医療のため",
              ]}
            />
            <DoubleDate
              change1={setWorkStartDate}
              change2={setWorkEndDate}
              varOnly={false}
            />
          </FormInputWrapper>
          {workHist.length < 5 ? <SmallCircleButton /> : ""}
          <WorkList workHist={workHist} />
          <NextButton />
          <BackButton />
        </form>
      </div>
    </>
  );
};

export default Form2;
