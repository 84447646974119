import React from "react";

interface Props {
  place: string;
  name?: string;
  label?: string;
  change: any;
  value: string;
  max: number;
  varOnly: boolean;
}

const TextInput: React.FC<Props> = ({ name, label, value, place, change, max, varOnly }) => {
  return (
    <>
    <label className="text-purple" htmlFor={name}>{label}:</label>
    <input
    aria-autoComplete
    className="input focus:outline-none"
    placeholder={place}
    name={name}
    value={value}
    type="text"
    maxLength={max}
    onChange={varOnly ? change : (e) => change(e.target.value)}
    />
    </>
  );
};

export default TextInput;
