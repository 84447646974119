import React, { useContext } from "react";

import CertHistInput from "../Page2SecParts/CertHistInput";
import CertLicTitle from "../Page2SecParts/CertLicTitle";
import TableHeader from "../../Page1/Page1SecParts/TableHeader";

import { Form3Context } from "../../../ResumeState/Form3State";

const CertHistory: React.FC = () => {
  const { certHist } = useContext(Form3Context);

  return (
    <>
      <section className="no-break mx-[5%] border-b-[0px]">
        <table className="w-full">
        <TableHeader padding="p-3" />
          <tbody>
            <CertLicTitle year="年（西暦）" month="月" text="資格・免許" />
            {certHist.map((item) => (
              
                <CertHistInput
                  key={item.certId}
                  text={item.certName}
                  year={item.certStartDate.slice(0, 4)}
                  month={item.certStartDate.slice(5, 7)}
                />
              
            ))}
            {certHist.length < 1 ? <CertHistInput /> : ""}
            {certHist.length < 2 ? <CertHistInput /> : ""}
            {certHist.length < 3 ? <CertHistInput /> : ""}
            {certHist.length < 4 ? <CertHistInput /> : ""}
            {certHist.length < 5 ? <CertHistInput /> : ""}
            {certHist.length < 6 ? <CertHistInput /> : ""}
          </tbody>
        </table>
      </section>
    </>
  );
};

export default CertHistory;
