import React, { ReactFragment, useContext } from "react";
import { AppContext } from "../../AppState/AppState";
import CloseButton from "../Buttons/CloseButton";

interface Props {
  content: ReactFragment;
  special?: boolean;
}

const TipBox: React.FC<Props> = ({ special, content }) => {
  const {language, USF} = useContext(AppContext);

  return (
    <>
      <section className={special ? "w-full bg-gray bg-opacity-30 h-full" : "xl:w-1/2 bg-gray bg-opacity-30 h-full"}>
        <div className="page-div drop-shadow-lg tipbox">
          <CloseButton />
          <h1 className="h1">{language === USF ? "Tips" : "チップス"}</h1>
          <div className="mb-4">{content}</div>
        </div>
      </section>
    </>
  );
};

export default TipBox;
