import React, { useContext } from "react";
import SingleCertItem from "./SingleCertItem";

import { Form3Cert } from "../../ResumeState/Form3State";
import { AppContext } from "../../../../AppState/AppState";

interface Props {
  certHist: Form3Cert[];
}

const CertList: React.FC<Props> = ({certHist}) => {
  const {language, USF} = useContext(AppContext);
  return (
    <>
    <div className="mt-4">
    <span className="text-purple text-xl">{language === USF ? "Certification and License List" : "証明書と免許リスト"}</span>
    {
      certHist.map((item) => 
      (<SingleCertItem
        key={item.certId}
        item={item}
        />))
    }
      </div>
    </>
  );
};

export default CertList;
