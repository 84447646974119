import React, { useContext } from 'react';

import SingleProject from './SingleProject';

import { Projects } from '../../../SkillSheetState/SSForm2State';
import { AppContext } from '../../../../../AppState/AppState';

interface Props {
    Projects: Projects[];
}

const ProjectList: React.FC<Props> = ({Projects}) => {
    const {language, USF} = useContext(AppContext);
  return (
    <>
    <span className="text-xl text-purple">{language === USF ? "Projects List" : "プロジェクトリスト"}</span>
    <div className="mt-4 flex justify-around flex-wrap">
    {
      Projects.map((item) => 
      (<SingleProject
        key={item.id}
        item={item}
        />))
    }
      </div>
    </>
  )
}

export default ProjectList