import React from "react";

import ProjWorkplace from "../ProjectSectionComponents/ProjWorkplace";
import ProjInfoSect from "../ProjectSectionComponents/ProjInfoSect";
import ProjLangSect from "../ProjectSectionComponents/ProjLangSect";
import ProjectEnvironment from "../ProjectSectionComponents/ProjectEnvironment";
import ProjDatesSect from "../ProjectSectionComponents/ProjDatesSect";

import { ProjLanguages } from "../../SkillSheetState/SSForm2State";

interface Props {
  workPlace?: string;
  projName?: string;
  projStart?: string;
  projEnd?: string;
  overview?: string;
  points?: string;
  ProjLanguages?: ProjLanguages[];
  position?: string;
  numberOfPers?: string;
  scale?: string;
  requirements?: boolean;
  basicDesign?: boolean;
  detailedDesign?: boolean;
  implementation?: boolean;
  simpleTest?: boolean;
  conclusionTest?: boolean;
  maintenance?: boolean;
}

const Project: React.FC<Props> = ({
  workPlace,
  projName,
  projStart,
  projEnd,
  overview,
  points,
  position,
  scale,
  ProjLanguages,
  numberOfPers,
  requirements,
  basicDesign,
  detailedDesign,
  implementation,
  simpleTest,
  conclusionTest,
  maintenance,
}) => {
  const months =
    projEnd && projStart
      ? (+projEnd.slice(0, 4) - +projStart.slice(0, 4)) * 12 +
        (+projEnd.slice(5, 7) - +projStart.slice(5, 7))
      : "";

  return (
    <>
      <div className="project-tables">
        <ProjWorkplace workPlace={workPlace} />
        <table className="w-full border-b-2">
          <tbody className="table">
            <ProjDatesSect
              months={months}
              projEnd={projEnd}
              projStart={projStart}
            />
            <ProjInfoSect
              projName={projName}
              overview={overview}
              points={points}
            />
            <ProjLangSect ProjLanguages={ProjLanguages} />
            <ProjectEnvironment
              position={position}
              scale={scale}
              numberOfPers={numberOfPers}
              requirements={requirements}
              basicDesign={basicDesign}
              detailedDesign={detailedDesign}
              implementation={implementation}
              simpleTest={simpleTest}
              conclusionTest={conclusionTest}
              maintenance={maintenance}
            />
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Project;
