import React from "react";

interface Props {
  change: any;
  name?: string;
  label?: string;
}

const EmailInput: React.FC<Props> = ({ name, label, change }) => {
  return (
    <>
      <label className="text-purple" htmlFor={name}>{label}:</label>
      <input
        aria-autoComplete
        className="input focus:outline-none"
        placeholder="abc123@gmail.com"
        type="email"
        onChange={change}
        maxLength={50}
      />
    </>
  );
};

export default EmailInput;
