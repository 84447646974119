import React from "react";

import TipReferences from "../../TipBoxes/TipReferences";
import TipsSection from "../../TipBoxes/TipsSection";
import TipSubSect from "../../TipBoxes/TipSubSect";

const Form2Tips: React.FC = () => {
  return (
    <>
      <div className="tips-margin">
        <TipReferences
          ref1={"https://blog.gaijinpot.com/write-japanese-resume"}
          ref2={
            "https://jobs.bfftokyo.com/ultimate-guide-to-writing-a-japanese-resume"
          }
        />
        <TipsSection
          title="Educational Background:"
          text="Please fill in all the fields and the dates for each entry and click
            on the orange add button. Start with the earliest educational
            history entry (highschool) and continue to the most recent. Use the
            official school name, department, and major. If you need help use
            the transcript and a digital translator. Do not add '学部' or '学科' to the end of the Major or Department
            category."
        />
        <TipSubSect
          title="Result:"
          listTitle="For result you can use:"
          listItems={[
            "zaigaku 在学 (currently enrolled student)",
            "sotsugyou 卒業 (graduated)",
          ]}
        />
        <TipsSection
          title="Work History:"
          text="Please fill in all the fields and the dates for each entry and click
          on the orange add button. Start with the earlist work history entry
          and continue to the most recent. You do not need your job description
          or details of what you did. If you have no experience you can put
          無し."
        />
        <TipSubSect
          title="Explanation for Starting Work:"
          listTitle="The most common reasons are:"
          listItems={[
            "入社 (joined the company)",
            "契約期間の初め (contract started)",
            "他の　(other)",
          ]}
        />
        <TipSubSect
          title="Reason for Leaving:"
          extras="If it is your current job you can write 現在に至る instead of leaving
          it blank."
          listTitle="Other very common entries for leaving the company are:"
          listItems={[
            "一身上の都合により退職 (left for personal reasons)",
            "契約期間満了につき退社 (contract ended)",
            "会社都合により退社 (left for company reasons)",
            "結婚のために (Marriage)",
            "出産のために (Childbirth)",
            "家族の介護のため (Family care for parents, relatives, etc.)",
            "病院医療のため (Presently Working Here)",
          ]}
        />
      </div>
    </>
  );
};

export default Form2Tips;
