import React from "react";

import PrintButtonGroup from "../../../Buttons/PrintButtonGroup";

const SSForm4: React.FC = () => {
  return (
    <>
      <PrintButtonGroup />
    </>
  );
};

export default SSForm4;
