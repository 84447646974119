import React from "react";

interface Props {
  label: string;
  p2: boolean;
  variable1: any;
  variable2?: string;
}

const ProjectEnvironSect: React.FC<Props> = ({
  label,
  p2,
  variable1,
  variable2,
}) => {
  return (
    <>
      <td className="bg-skillsheetgray short-cell">{label}</td>
      <td className="medium-cell h-12">
        <p className="text-center">{variable1}</p>
        {p2 ? <p className="text-center">{variable2}</p> : ""}
      </td>
    </>
  );
};

export default ProjectEnvironSect;
