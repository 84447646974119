import React, { useContext } from "react";

import FormInputWrapper from "../../../../FormInputFields/FormInputWrapper";
import TextArea from "../../../../FormInputFields/TextArea";
import TextInput from "../../../../FormInputFields/TextInput";
import DoubleDate from "../../../../FormInputFields/DoubleDate";

import { ProjectsContext } from "../../../SkillSheetState/SSForm2State";
import { AppContext } from "../../../../../AppState/AppState";

const ProjectInfo: React.FC = () => {
  const {language, USF} = useContext(AppContext);
  
  const {
    workPlace,
    projName,
    setWorkPlace,
    setProjName,
    setProjStart,
    setProjEnd,
    setOverview,
    setPoints,
  } = useContext(ProjectsContext);

  const US = {
    work: "Work Place",
    project: "Project Name (Required)",
    overview: "Overview",
    points: "Points",
    overviewPlace: "Overview of the Project",
    pointsPlace: "Important Points of the Project",
  }

  const JP = {
    work: "勤務先",
    project: "案件名 (必要)",
    overview: "概要",
    points: "ポイント",
    overviewPlace: "プロジェクトの概要",
    pointsPlace: "プロジェクトの主な点",
  }

  let lang = US;
  language === USF ? (lang = US) : (lang = JP);

  return (
    <section className="section mx-5">
      <FormInputWrapper label={lang.work}>
        <TextInput
          max={40}
          value={workPlace}
          label={lang.work}
          place="フリーランス"
          change={setWorkPlace}
          varOnly={true}
        />
      </FormInputWrapper>
      <FormInputWrapper label={lang.project}>
        <TextInput
          max={25}
          value={projName}
          label={lang.project}
          place="無料　履歴書　ビルダ"
          change={setProjName}
          varOnly={true}
        />
      </FormInputWrapper>
      <DoubleDate change1={setProjStart} change2={setProjEnd} varOnly={true} />
      <TextArea
        title={lang.overview}
        label={lang.overview}
        name="Overview"
        varOnly={true}
        max={100}
        place={lang.overviewPlace}
        change={setOverview}
        classString="small-text-area"
      />
      <TextArea
        title={lang.points}
        label={lang.points}
        name="Points"
        varOnly={true}
        max={100}
        place={lang.pointsPlace}
        change={setPoints}
        classString="small-text-area"
      />
    </section>
  );
};

export default ProjectInfo;
