import React, { useContext } from "react";

import BackButton from "./BackButton";
import PrintButton from "./PrintButton";

import { AppContext } from "../../AppState/AppState";

const PrintButtonGroup = () => {
  const { language, USF } = useContext(AppContext);

  return (
    <>
      <div
        id="skillsheet-button-group"
        className="grid grid-flow-row md:grid-flow-col xl:grid-flow-row md:justify-evenly w-full"
      >
        <BackButton />
        <p className="flex items-center justify-center text-red-500 text:lg xl:text-xl">
          {language === USF
            ? "Use A4 Page Size to Print"
            : "おすすめのページサイズはA4です。"}
        </p>
        <PrintButton />
      </div>
    </>
  );
};

export default PrintButtonGroup;
