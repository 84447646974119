import React, { useContext } from "react";

import CertHistory from "./Page2Sections/CertHistory";
import AdditionalInfo from "./Page2Sections/AdditionalInfo";
import AppealFamily from "./Page2Sections/AppealFamily";

import { AppContext } from "../../../../AppState/AppState";

const ResumePage2: React.FC = () => {
  const { step, language, USF } = useContext(AppContext);

  return (
    <>
      <section
        id="resume-page-2-bg"
        className={
          step < 6 && step > 1
            ? `hidden xl:w-[60%] w-full bg-gray bg-opacity-30 md:block h-full pb-3`
            : step > 1 ? `block xl:w-1/2 h-full bg-gray bg-opacity-30` :
            `hidden md:block xl:w-1/2 h-full bg-gray bg-opacity-30`
        }
      >
        {step < 6 ? (
          <h2 className="resume-bg-text">
        {language === USF ? "Resume May Appear Differently When Printed" : "印刷すると履歴書の表示が異なります。"}
          </h2>
        ) : (
          ""
        )}
        <div
          id="resume-page-2"
          className={`page-div ${step < 6 ? `drop-shadow-lg w-[210mm]` : `w-[210mm] xl:w-[95%]`} pt-5`}
        >
          <CertHistory />
          <AppealFamily />
          <AdditionalInfo />
        </div>
      </section>
    </>
  );
};

export default ResumePage2;
