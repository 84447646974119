import React, { useContext } from "react";

import { faArrowAltCircleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { AppContext } from "../../AppState/AppState";

interface Props {
  value: string;
  name: string;
  change: any;
  max?: number;
  varOnly: boolean;
  listName: string;
  label: string;
  optionList: string[];
}

const DataList: React.FC<Props> = ({
  label,
  name,
  max,
  varOnly,
  value,
  change,
  listName,
  optionList,
}) => {

  const {language, USF} = useContext(AppContext);
  
  return (
    <>
      <label htmlFor={name} className="text-purple">
        {label}:
      </label>
      <input aria-autoComplete
        list={listName}
        name={name}
        maxLength={max}
        value={value}
        onChange={varOnly ? change : (e) => change(e.target.value)}
        placeholder={language === USF ? "Press the down key or double click" : "↓ボタンかダブルクリック"}
        className="input focus:outline-none"
      />
      <FontAwesomeIcon
        className="relative bottom-10 left-72 text-purple"
        icon={faArrowAltCircleDown}
      />
      <datalist id={listName}>
        {optionList.map((option, index) => (
          <option key={index} value={option} />
        ))}
      </datalist>
    </>
  );
};

export default DataList;
