import React, { useContext } from "react";

import FormInputWrapper from "../../../FormInputFields/FormInputWrapper";
import TextInput from "../../../FormInputFields/TextInput";
import TextArea from "../../../FormInputFields/TextArea";
import NextButton from "../../../Buttons/NextButton";

import { SkillSheetContext } from "../../SkillSheetState/SkillSheetState";
import { AppContext } from "../../../../AppState/AppState";

const SSForm1: React.FC = () => {
  const {language, USF} = useContext(AppContext);

  const {
    nameFurigana,
    name,
    setNameFurigana,
    setName,
    setWorkSummary,
    setKandE,
    setTechnology,
    setOtherInfo,
  } = useContext(SkillSheetContext);

  const US = {
    BasicInfo: "Basic Information",
    nameFuri: "Name Furigana",
    name: "Name",
    workSum: "Work Summary",
    workSumPlace: "Type a Short Job Description",
    KandE: "Knowledge and Experience",
    KandEPlace: "Knowledge and experience gained from working",
    tech: "Technology",
    techPlace: "Technology you can use or know",
    otherInfo: "Other Information",
  }

  const JP = {
    BasicInfo: "基本的な情報",
    nameFuri: "名前（ふりがな）",
    name: "名前",
    workSum: "事務要約",
    workSumPlace: "簡単に事務要約を入力して下さい。",
    KandE: "活かせる経験・知識",
    KandEPlace: "活かせる経験と知識を入力して下さい。",
    tech: "保有技術",
    techPlace: "使える技術かよく知っている技術",
    otherInfo: "その他",
  }

  let lang = US;
  language === USF ? (lang = US) : (lang = JP);

  const onSubmitHandler = (e: React.FormEvent) => {
    e.preventDefault();
  };

  return (
    <>
      <form onSubmit={onSubmitHandler} className="w-full mb-5 xl:w-1/2 h-fit">
          <FormInputWrapper label={lang.BasicInfo} classString="section">
            <TextInput
              value={nameFurigana}
              place="ほんだ・あいり"
              label={lang.nameFuri}
              name="nameFurigana"
              change={setNameFurigana}
              max={26}
              varOnly={true}
            />
            <TextInput
              value={name}
              place="本田・愛梨"
              label={lang.name}
              name="name"
              change={setName}
              max={26}
              varOnly={true}
            />
          </FormInputWrapper>
          <TextArea
            title=""
            label={lang.workSum}
            name="workSummary"
            max={48}
            place={lang.workSumPlace}
            change={setWorkSummary}
            varOnly={true}
            classString="small-text-area"
          />
          <TextArea
            label={lang.KandE}
            name="KandE"
            max={75}
            place={lang.KandEPlace}
            change={setKandE}
            varOnly={true}
            classString="small-text-area"
          />
          <TextArea
            label={lang.tech}
            name="Technology"
            max={70}
            place={lang.techPlace}
            change={setTechnology}
            varOnly={true}
            classString="small-text-area"
          />
          <TextArea
            label={lang.otherInfo}
            name="otherInformation"
            max={85}
            place="github: https://github.com/xxxxx"
            change={setOtherInfo}
            varOnly={true}
            classString="small-text-area"
          />
        <NextButton />
      </form>
    </>
  );
};

export default SSForm1;
