import React, { useContext } from "react";

import { AppContext } from "../../AppState/AppState";

interface Props {
    label: string;
    value: any;
    action1: any;
    action2: any;
}

const SelectInput: React.FC<Props> = ({label, value, action1, action2}) => {
  const { language, USF } = useContext(AppContext);

  return (
    <div className="family-drop-down-div w-1/2">
      <label htmlFor={label}>{label}</label>
      <select
        onChange={!value ? action1 : action2}
        className="drop-down flex shadow-lg"
        name={label}
      >
        <option onClick={action2} value="no">
          {language === USF ? "No" : "無"}
        </option>
        <option onClick={action1} value="yes">
        {language === USF ? "Yes" : "有"}
        </option>
      </select>
    </div>
  );
};

export default SelectInput;
