import React from 'react';

interface Props{
    workPlace?: string;
}

const ProjWorkplace: React.FC<Props> = ({workPlace}) => {
  return (
    <table className="w-full">
    <tbody className="mt-5 table">
      <tr className="info-table-row">
        <td className="grid place-content-center p-1 h-8">勤務先</td>
      </tr>
      <tr className="grid col-span-10">
        <td className="p-1 text-sm">{workPlace}</td>
      </tr>
    </tbody>
  </table>
  )
}

export default ProjWorkplace