import React from "react";

import SubSectionComp from "./SubSectionComp";

import {
  faFileCirclePlus,
  faPrint,
  faQuestionCircle,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

interface Props {
  subText: string;
  sub1Text: string;
  sub2Text: string;
  sub3Text: string;
  footerText1: string;
  footerText2: string;
}

const HomeSubSection: React.FC<Props> = ({subText, sub1Text, sub2Text, sub3Text, footerText1, footerText2}) => {
  return (
    <>
      <div className="fixed bottom-0 w-full flex justify-around bg-white pt-4 pb-12">
        <SubSectionComp icon={faQuestionCircle} text={subText} />
        <SubSectionComp icon={faFileCirclePlus} text={sub1Text} />
        <SubSectionComp icon={faSearch} text={sub2Text} />
        <SubSectionComp icon={faPrint} text={sub3Text} />
        <footer className="absolute bottom-0 py-2 px-4 w-full">
          <p className="text-purple text-center text-xs md:text-sm">{footerText1}</p>
          <p className="text-purple text-center text-xs md:text-sm">{footerText2}</p>
        </footer>
      </div>
    </>
  );
};

export default HomeSubSection;
