import React from "react";

interface Props {
  change: any;
  label?: string;
  name?: string;
}

const PhoneInput: React.FC<Props> = ({ name, label, change }) => {
  return (
    <>
    <label htmlFor={name} className="text-purple">{label}:</label>
      <input
        aria-autoComplete
        className="input focus:outline-none"
        placeholder="(111) 2222-3333"
        name={name}
        type="tel"
        maxLength={15}
        onChange={change}
      />
    </>
  );
};

export default PhoneInput;
