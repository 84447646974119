import React, { useContext } from "react";

import TableHeader from "../../Page1/Page1SecParts/TableHeader";

import { Form4To5Context } from "../../../ResumeState/Form4To5State";

const AdditionalInfo: React.FC = () => {
  const { additionalInfo } = useContext(Form4To5Context);

  return (
    <>
      <section className="no-break mx-[5%]">
        <table className="w-full">
          <TableHeader padding="p-3" />
          <tbody className="form-text-area-box h-48 min-h-48 border-2">
            <tr className="w-full h-2 px-1 border-b-2 border-double text-sm text-center">
              <td>本人希望記入欄（特に給料・職種・勤務時間・勤務地・その他についての希望などがあれば記入）</td>
            </tr>
            <tr>
              <td className="w-full block p-2 m-2 min-h-full text-xs">
                {additionalInfo}
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </>
  );
};

export default AdditionalInfo;
