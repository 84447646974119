import React from "react";

import TipReferences from "../../TipBoxes/TipReferences";
import TipsSection from "../../TipBoxes/TipsSection";
import TipSubSect from "../../TipBoxes/TipSubSect";

const Form3Tips: React.FC = () => {
  return (
    <>
      <div className="tips-margin">
        <TipReferences
          ref1="https://japanbased.com/write-japanese-resume"
          ref2="https://blog.gaijinpot.com/write-japanese-resume"
          ref3="https://jobs.bfftokyo.com/ultimate-guide-to-writing-a-japanese-resume"
        />
        <TipsSection
          title="Certifications and Licenses:"
          text="List only your licenses and certificates related to the position you
          are applying for starting from the newest to the oldest. Some common
          licenses and certificates are drivers license (of any type),
          TOEIC/TOEFL, JLPT (Japanese Langauge Proficiency Test), Kanji Kanken
          Level, etc... You can use the drop down list for examples."
        />
        <TipsSection
          title="Appeal Points:"
          text="This is the section in which you can sell yourself. points. You can
          describe your special skills and interests. This section is important.
          It is your chance to get the employers attention with something about
          yourself they would want to know. Why are you a good choice for this
          position? A common phrase if you want to use the same resume for
          multiple companies or do not know what to write is
          '営業経験を活かして、 [field of work] の仕事にて活躍したい'."
        />
        <TipsSection
          title="Travel Time:"
          text="How much time will it take to travel one way to commute to work. List
          the hours and minutes. Japanese companies usually pay travel expenses
          and prefer people who live closer to the place of work."
        />
        <TipsSection title="Family:" text="" />
        <TipSubSect
          listTitle="Dependents (Not including Spouse):"
          listItems={["List all dependents not including spouse", "It must be a number.  If more than 10 make a comment in the additional information section."]}
        />
        <TipSubSect
          listTitle="Spouse:"
          listItems={["Are you married?", "有る (Yes) or 無い (No)"]}
        />
        <TipSubSect
          listTitle="Obligation to Support Spouse:"
          listItems={["Is your spouse working and receiving social insurance? Then circle 無い (No)", "Are you planning on supporting your spouse with your income? Then circle 有る (Yes)"]}
        />
        <TipsSection
          title="Additional Information:"
          text="Tell the reader about any other details you want them to know such as
          desired salary, type of work, travel distance, work location, and any
          other requests etc... You can write 特に無し (nothing in particular),
          but most people write 貴社規定に従います (I will comply with your
          company regulations)."
        />
      </div>
    </>
  );
};

export default Form3Tips;
