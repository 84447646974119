import React from "react";

interface Props {
    bg: boolean;
}

const MiniTableContainer: React.FC<Props> = ({ bg, children }) => {
  return (
    <td className={bg ? `bg-skillsheetgray mini-table-container` : `mini-table-container border-b-2`}>
    <table className="w-full">
      <tbody className="mini-table">{children}</tbody>
    </table>
    </td>
  );
};

export default MiniTableContainer;
