import React from "react";

import EducHistory from "./EducHistory";
import WorkHistory from "./WorkHistory";

const ResumeHistory: React.FC = () => {
  return (
    <section id="history-section" className="mx-[5%] mt-8 border-t-[1px]">
          <EducHistory />
          <WorkHistory />
    </section>
  );
};

export default ResumeHistory;
