import React, { useContext } from "react";
import { AppContext } from "../../../../../AppState/AppState";

import FormInputWrapper from "../../../../FormInputFields/FormInputWrapper";
import InputTitle from "../../../../FormInputFields/InputTitle";
import NumberInput from "../../../../FormInputFields/NumberInput";
import SelectInput from "../../../../FormInputFields/SelectInput";
import TextInput from "../../../../FormInputFields/TextInput";

import { ProjectsContext } from "../../../SkillSheetState/SSForm2State";

const Environment: React.FC = () => {
  const {language, USF} = useContext(AppContext);

  const {
    position,
    requirements,
    basicDesign,
    detailedDesign,
    implementation,
    simpleTest,
    conclusionTest,
    maintenance,
    setPosition,
    setNumberOfPers,
    setScale,
    setRequirements,
    setNoRequirements,
    setBasicDesign,
    setNoBasicDesign,
    setDetailedDesign,
    setNoDetailedDesign,
    setImplementation,
    setNoImplementation,
    setSimpleTest,
    setNoSimpleTest,
    setConclusionTest,
    setNoConclusionTest,
    setMaintenance,
    setNoMaintenance,
  } = useContext(ProjectsContext);

  const US = {
    role: "Role/Position",
    scalePers: "Scale/Number of Personnel",
    pers: "Personnel",
    scale: "Scale",
    processes: "Processes Followed",
    requirements: "Requirements",
    basicDesign: "Basic Design",
    detailedDesign: "Detailed Design",
    implementation: "Implementation",
    simpleTest: "Simple Test",
    conclusionTest: "Conclusion Test",
    maintenance: "Maintenance/Operation",
  }
  const JP = {
    role: "役割・役職",
    scalePers: "規模・人数",
    pers: "人数",
    scale: "規模",
    processes: "担当工程",
    requirements: "要件定義",
    basicDesign: "基本設計",
    detailedDesign: "詳細設計",
    implementation: "実装",
    simpleTest: "単テスト",
    conclusionTest: "結テスト",
    maintenance: "保守運用",
  }

  let lang = US;
  language === USF ? (lang = US) : (lang = JP);

  return (
    <section className="section mx-5">
      <FormInputWrapper label={lang.role}>
        <TextInput
          max={23}
          value={position}
          label={lang.role}
          place="メンバー（フロントエンド）"
          change={setPosition}
          varOnly={true}
        />
      </FormInputWrapper>
      <InputTitle fieldName={lang.scalePers} />
      <NumberInput
        divName="family-drop-down-div float-left flex"
        name="NumberOfPersonnnel"
        max={1000}
        label={lang.pers}
        change={setNumberOfPers}
        varOnly={true}
      />
      <NumberInput
        divName="family-drop-down-div float-left flex"
        name="scale"
        max={1000}
        label={lang.scale}
        change={setScale}
        varOnly={true}
      />
      <InputTitle fieldName={lang.processes} />
      {/* First drop downs */}
      <div className="select-input-div">
        <SelectInput
          label={lang.requirements}
          value={requirements}
          action1={setRequirements}
          action2={setNoRequirements}
        />
        <SelectInput
          label={lang.basicDesign}
          value={basicDesign}
          action1={setBasicDesign}
          action2={setNoBasicDesign}
        />
      </div>
      {/* First drop downs */}
      {/* Second drop downs */}
      <div className="select-input-div">
        <SelectInput
          label={lang.detailedDesign}
          value={detailedDesign}
          action1={setDetailedDesign}
          action2={setNoDetailedDesign}
        />
        <SelectInput
          label={lang.implementation}
          value={implementation}
          action1={setImplementation}
          action2={setNoImplementation}
        />
      </div>
      {/* Second drop downs */}
      {/* Third drop downs */}
      <div className="select-input-div">
        <SelectInput
          label={lang.simpleTest}
          value={simpleTest}
          action1={setSimpleTest}
          action2={setNoSimpleTest}
        />
        <SelectInput
          label={lang.conclusionTest}
          value={conclusionTest}
          action1={setConclusionTest}
          action2={setNoConclusionTest}
        />
      </div>
      {/* Third drop downs */}
      {/* Last drop down */}
      <div className="select-input-div">
        <SelectInput
          label={lang.maintenance}
          value={maintenance}
          action1={setMaintenance}
          action2={setNoMaintenance}
        />
      </div>
      {/* Last drop down */}
    </section>
  );
};

export default Environment;
