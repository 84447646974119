import React, { useContext } from "react";
import { Form2Context } from "../../../ResumeState/Form2State";

interface Props {
  text: string;
}

const DoubleHistoryRow: React.FC<Props> = ({ text }) => {
  const { workHist } = useContext(Form2Context);
  return (
    // This component is used to avoid improper page breaks
    <>
      {workHist.length > 4 ? (
        <tr className="no-break resume-history-div">
          <td className="resume-history-year-div"></td>
          <td className="resume-history-month-div"></td>
          <td className="resume-history-text-div"></td>
        </tr>
      ) : (
        ""
      )}
      <tr className="no-break resume-history-div">
        <td className="resume-history-year-div"></td>
        <td className="resume-history-month-div"></td>
        <td className="resume-history-text-div text-right">
          <p>{text}</p>
        </td>
      </tr>
    </>
  );
};

export default DoubleHistoryRow;
