import React, { useContext } from "react";

import Form3 from "../ResumeForms/Form3/Form3";
import ResumePage2 from "../ResumePages/Page2/ResumePage2";

import TipBox from "../../TipBoxes/TipBox";
import Form3Tips from "../ResumeTipBoxes/Form3Tips";
import JP3Tips from "../ResumeTipBoxes/JP3Tips";

import { AppContext } from "../../../AppState/AppState";

const ResumeStep3: React.FC = () => {
  const { language, USF, showPopup } = useContext(AppContext);

  return (
    <>
      <div className="grid xl:flex justify-center">
          <Form3 />
          {!showPopup ? (
            <ResumePage2 />
          ) : (
            <TipBox content={language === USF ? <Form3Tips /> : <JP3Tips />} />
          )}
      </div>
    </>
  );
};

export default ResumeStep3;
