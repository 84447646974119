import React from "react";

interface Props {
  text?: string;
  year?: string;
  month?: string;
  extraClass?: string;
}

const HistoryTitle: React.FC<Props> = ({ year, month, text, extraClass }) => {
  return (
    <>
      <thead className="no-break grid w-full">
        <tr className={`resume-history-div border-x-2 ${extraClass}`}>
          <th className="resume-history-year-div">{year}</th>
          <th className="resume-history-month-div">{month}</th>
          <th className="resume-history-title-div">{text}</th>
        </tr>
      </thead>
    </>
  );
};

export default HistoryTitle;
