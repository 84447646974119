import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface Props {
  text?: string;
  last: boolean;
  dot: boolean;
  variable?: any;
}

const MiniTableRow: React.FC<Props> = ({ text, last, dot, variable }) => {
  return (
    <>
      <tr className={last ? `last-cell` : `mini-table-row`}>
        {dot ? (
          <td>{variable ? <FontAwesomeIcon icon={faCircle} /> : ""}</td>
        ) : (
          <td className="mini-table-td">{text}</td>
        )}
      </tr>
    </>
  );
};

export default MiniTableRow;
