import React, { useContext } from "react";

import StartEndDate from "./StartEndDate";

import { AppContext } from "../../AppState/AppState";

interface Props {
    change1: any;
    change2: any;
    varOnly: boolean;
}

const DoubleDate: React.FC<Props> = ({change1, change2, varOnly}) => {
  const {language, USF} = useContext(AppContext)
  return (
    <div className="date-input-wrapper">
      <StartEndDate
        label={language === USF ? "Start Date:" : "開始日:"}
        change={change1}
        varOnly={varOnly}
      />
      <StartEndDate label={language === USF ? "End Date:" : "終了日:"} change={change2} varOnly={varOnly} />
    </div>
  );
};

export default DoubleDate;
