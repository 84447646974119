import React from "react";

interface Props {
  months?: number | string;
  projEnd?: string;
  projStart?: string;
}

const ProjDatesSect: React.FC<Props> = ({ months, projEnd, projStart }) => {
  const years: number = Math.floor(Number(months) / 12);
  const remainderMonths: number = Number(months) % 12;
  
  return (
    <tr className="grid col-span-2 text-sm border-r-2">
      <td className="bg-skillsheetgray short-cell">案件名</td>
      <td className="no-bottom-medium-cell min-h-[220px]">
        {projStart}~<span className="break-words">{projEnd}</span>
        <br />
        {Number(months) < 12 ? (<span>（{months}カ月）</span>) : (<span>（{years}年{remainderMonths}カ月）</span>)}
      </td>
    </tr>
  );
};

export default ProjDatesSect;
