import React from "react";
import TipReferences from "../../TipBoxes/TipReferences";

import TipsSection from "../../TipBoxes/TipsSection";
import TipSubSect from "../../TipBoxes/TipSubSect";

const JPMainTB: React.FC = () => {
  return (
    <>
      <div className="tips-margin">
        <h2 className="tip-step-subtitle">ステップ 1</h2>
        <TipReferences
          ref1="https://doda.jp/engineer/guide/qa/007.html"
          ref2="https://next.rikunabi.com/tenshokuknowhow/archives/22848/"
        />
        <TipsSection title="名前:" text="" />
        <TipSubSect
          listTitle="名前（ふりがな）"
          listItems={["ふりがなで氏名を入力して下さい。"]}
        />
        <TipSubSect
          listTitle="名前:"
          listItems={[
            "氏名を入力して下さい。漢字の名前方がいいですが、英語の字も使ってもいいです。",
          ]}
        />
        <TipsSection
          title="事務要約:"
          text="簡単に今の仕事か最も最近の仕事の事務要約を入力して下さい。"
        />
        <TipsSection
          title="活かせる経験・知識:"
          text="活かせる経験・知識を入力して下さい。"
        />
        <TipsSection
          title="保有技術:"
          text="使える技術や解る技術を入力して下さい。"
        />
        <TipsSection
          title="その他:"
          text="簡単に他の大切な情報を説明して下さい。"
        />
        <h2 className="tip-step-subtitle">ステップ 3</h2>
        <TipsSection
          title="PR:"
          text="自分のことについて話しています。 特別な技や興味を伝えて下さい。この部分は大切です。
        なぜあなたはこの仕事をしたいと思いますか？ 複数の会社のために同じ履歴書を使いたいと思えば、
        「営業経験を活かして、 [field of work] の仕事にて活躍したい』を書いてもいいです。"
        />
      </div>
    </>
  );
};

export default JPMainTB;
