import React, { useContext } from "react";

import Form1 from "../ResumeForms/Form1/Form1";
import ResumePage1 from "../ResumePages/Page1/ResumePage1";

import TipBox from "../../TipBoxes/TipBox";
import Form1Tips from "../ResumeTipBoxes/Form1Tips";
import JP1Tips from "../ResumeTipBoxes/JP1Tips";

import { AppContext } from "../../../AppState/AppState";

const ResumeStep1: React.FC = () => {
  const { language, USF, showPopup } = useContext(AppContext);

  return (
    <>
      <div className="grid xl:flex justify-center">
        <Form1 />
        {!showPopup ? (
          <ResumePage1 />
        ) : (
          <TipBox content={language === USF ? <Form1Tips /> : <JP1Tips />} />
        )}
      </div>
    </>
  );
};

export default ResumeStep1;
