import React, { useContext } from "react";

import { ReducerContext } from "../../../ResumeState/ResumeState";

const PersonalInfo: React.FC = () => {
  const {
    pic,
    gender,
    furiName,
    name,
    dob,
  } = useContext(ReducerContext);

  const d = new Date();
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const date = d.getDate();

  const birthyear = +dob.slice(0, 4);
  const birthmonth = +dob.slice(5, 7);
  const birthdate = +dob.slice(8);

  const showa =
    (dob && birthyear < 1989) ||
    (birthyear === 1989 && birthmonth === 1 && birthdate <= 7);
  const heisei =
    birthyear > 1989 ||
    (birthyear === 1989 && birthmonth >= 1 && birthdate >= 8);
  const showayear = birthyear - 1925;
  const heiseiyear = birthyear - 1988;

  const age = () => {
    return +month >= +birthmonth && +date >= +birthdate
      ? year - birthyear
      : year - birthyear - 1;
  };
  return (
    <section className="resume-section">
      <table className="col-span-3">
        <tbody className="col-span-3 border-2 border-b-[1px]">
          <tr className="">
            <td className="w-full flex items-center border-b-[1px] text-xs pl-2"><span className="grid place-content-center min-w-fit">ふりがな</span><span className="pl-5">{furiName}</span></td>
          </tr>
          <tr className="h-24">
            <td className="flex h-24 text-2xl py-4 px-3"><span className="min-w-fit h-full grid place-content-center">氏名</span> <span className="text-3xl grid place-content-center pl-5">{name}</span></td>
          </tr>
          <tr className="grid grid-flow-col col-span-2 text-xs border-t-2">
            <td className="flex">
              <p className="w-1/2 px-2 pt-2">生年月日</p>
              <div className="flex flex-col w-full pr-2 py-2">
                <p className="flex justify-end">({dob ? age() : "00"}歳)</p>
                <div className="flex py-1 text-right justify-end">
                  <p
                    className={
                      showa
                        ? `border-[1px] rounded-full relative bottom-1 p-1`
                        : ""
                    }
                  >
                    昭和
                  </p>
                  <p>・</p>
                  <p
                    className={
                      heisei
                        ? `border-[1px] rounded-full relative bottom-1 p-1`
                        : ""
                    }
                  >
                    平成
                  </p>
                  <p className="pl-2">
                    {birthyear > 0 && birthyear < year && heisei
                      ? heiseiyear
                      : birthyear <= 0
                      ? "00"
                      : showayear}
                    年{birthmonth ? birthmonth : "00"}月
                    {birthdate ? birthdate : "00"}日
                  </p>
                </div>
              </div>
            </td>
            <td className="grid grid-flow-col col-span-1 border-l-[1px] place-content-center">
              <span className="text-xl relative bottom-1 right-5">※</span>
              <span
                className={`${
                  gender === "male" ? "border-[1px] rounded-full p-1" : "p-1"
                }`}
              >
                男
              </span>
              <span className="px-2 py-1">・</span>
              <span
                className={`${
                  gender === "female" ? "border-[1px] rounded-full p-1" : "p-1"
                }`}
              >
                女
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      {/* Photo div */}
      <div className="col-span-1 grid place-content-center">
        <img className="photo" src={pic} alt="" />
      </div>
    </section>
  );
};

export default PersonalInfo;
