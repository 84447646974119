import React from 'react';

interface Props {
    text: string;
    year: string;
    month: string;
}

const CertLicTitle: React.FC<Props> = ({text, year, month}) => {
  return (
    <>
      <tr className="resume-history-header-div">
        <th className="resume-history-year-div">{year}</th>
        <th className="resume-history-month-div">{month}</th>
        <th className="resume-history-title-div">{text}</th>
      </tr>
    </>
  )
}

export default CertLicTitle