import React, { useContext } from "react";

import BackButton from "../../../Buttons/BackButton";
import PrintButton from "../../../Buttons/PrintButton";
import PrintPages from "../../ResumePages/PrintPages/PrintPages";

import { AppContext } from "../../../../AppState/AppState";

const Form6: React.FC = () => {
  const { language, USF } = useContext(AppContext);

  return (
    <>
      <div className="grid w-full">
        <div
          id="button-group"
          className="flex flex-wrap flex-col justify-start md:flex-row md:justify-evenly md:w-full w-full"
        >
            <BackButton />
          <p className="flex items-center justify-center text-red-500 text-xl">
            {language === USF
              ? "Use A4 Page Size to Print"
              : "おすすめのページサイズはA4です。"}
          </p>
          <PrintButton />
        </div>
        <div className="xl:flex grid ml-[20rem] md:ml-0 w-full">
          <PrintPages />
        </div>
      </div>
    </>
  );
};

export default Form6;
