import React from 'react';

interface Props {
    text: string;
    name: string;
    change: any;
    value: string;
}

const RadioInput: React.FC<Props> = ({text, name, change, value}) => {
  return (
    <div>
    <input
      className="input mr-2"
      type="radio"
      name={name}
      value={text}
      onChange={change}
    />
    <label className="text-purple" htmlFor={value}>
      {text}
    </label>
  </div>
  )
}

export default RadioInput