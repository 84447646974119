import React, { useContext } from "react";
import { AppContext } from "../../AppState/AppState";

interface Props{
  ref1: string;
  ref2?: string;
  ref3?: string;  
}

const TipReferences: React.FC<Props> = ({ref1, ref2, ref3}) => {
  const {language, USF} = useContext(AppContext);
  return (
    <>
      <h2 className="tips-h2">{language === USF ? "References:" : "援用"}</h2>
      <p className="text-left">
        <a
          href={ref1}
          target="_blank"
          rel="noopener noreferrer"
        >
          {ref1}
        </a>
      </p>
      <p className="text-left">
        <a
          href={ref2}
          target="_blank"
          rel="noopener noreferrer"
        >
          {ref2}
        </a>
      </p>
      <p className="text-left">
        <a
          href={ref3}
          target="_blank"
          rel="noopener noreferrer"
        >
          {ref3}
        </a>
      </p>
    </>
  );
};

export default TipReferences;
