import React, { useContext, useEffect } from "react";

import HistoryInput from "../Page1SecParts/HistoryInput";
import HistoryTitle from "../Page1SecParts/HistoryTitle";
import DoubleHistoryRow from "../Page1SecParts/DoubleHistoryRow";
import SingleHistoryRow from "../Page1SecParts/SingleHistoryRow";

import { Form2Context } from "../../../ResumeState/Form2State";
import TableHeader from "../Page1SecParts/TableHeader";

const WorkHistory: React.FC = () => {
  const { workHist, setWorkChildren, workChildren } = useContext(Form2Context);

  useEffect(() => {
    let x = document.getElementById("workTable")?.childElementCount;
    if (x) {
      setWorkChildren(x);
    }
  }, [setWorkChildren, workHist]);
  return (
    <>
      <table className="w-full my-[-40px]">
        {/* This is just being used in place of a page margin because the css page margin does not work */}
        <TableHeader padding="p-2" />
        <HistoryTitle text="職歴" />
        <tbody id="workTable">
          {workHist.map((item) => (
            <HistoryInput
              key={item.workId}
              startText={`${item.companyName} ${item.startExpl}`}
              startYear={item.workStartDate.slice(0, 4)}
              startMonth={item.workStartDate.slice(5, 7)}
              endText={`${item.companyName} ${item.endExpl}`}
              endYear={item.workEndDate.slice(0, 4)}
              endMonth={item.workEndDate.slice(5, 7)}
              placeName={item.companyName}
              inputs={item.startExpl}
              result={item.endExpl}
            />
          ))}
        </tbody>
        <tbody>
          {workHist.length < 1 ? <HistoryInput /> : ""}
          {workHist.length < 2 ? <SingleHistoryRow /> : ""}
          {workChildren < 4 ? <SingleHistoryRow /> : ""}
          {workChildren < 5 ? <SingleHistoryRow /> : ""}
          {workChildren < 6 ? <SingleHistoryRow /> : ""}
          {workChildren < 7 ? <SingleHistoryRow /> : ""}
          {workChildren < 8 ? <SingleHistoryRow /> : ""}
          <DoubleHistoryRow text="以上" />
        </tbody>
        {/* This is just being used in place of a page margin because the css page margin does not work */}
        <tfoot className="opacity-0 no-break">
          <tr className="">
            <td className="p-4">Hello</td>
          </tr>
        </tfoot>
      </table>
    </>
  );
};

export default WorkHistory;
