import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  icon: any;
  text: string;
}

const SubSectionComp: React.FC<Props> = ({ icon, text }) => {
  return (
    <div className="w-fit">
      <FontAwesomeIcon
        icon={icon}
        className="grid place-content-center text-purple w-full h-12 md:h-16"
      />
      <p className="text-purple text-center md:text-2xl my-2">{text}</p>
    </div>
  );
};

export default SubSectionComp;
