import React from "react";

import TipReferences from "../../TipBoxes/TipReferences";
import TipsSection from "../../TipBoxes/TipsSection";
import TipSubSect from "../../TipBoxes/TipSubSect";

const JP3Tips: React.FC = () => {
  return (
    <>
      <div className="tips-margin">
        <TipReferences ref1={"https://doda.jp/guide/rireki/template/"} />
        <TipsSection
          title="証明書・免許:"
          text="全ての仕事に関係のある免許と証明書だけ入力して下さい。最も最近から最
          も古い免許まで入力して下さい。有効日も大切です。"
        />
        <TipsSection
          title="アピールポイント:"
          text="自分のことについて話しています。 特別な技や興味を伝えて下さい。この部分は大切です。
          なぜあなたはこの仕事をしたいと思いますか？ 複数の会社のために同じ履歴書を使いたいと思えば、
          「営業経験を活かして、 [field of work] の仕事にて活躍したい』を書いてもいいです。"
        />
        <TipsSection
          title="通勤時間:"
          text="片道の通勤時間を入力して下さい。 一般的に、日本の会社は通勤料金を支払ってもらいます。
          それでは日本の会社は勤務地に近く住んでいる求職者を好みます。"
        />
        <TipsSection title="家族:" text="" />
        <TipSubSect
          listTitle="扶養家族数（配偶を除く）:"
          listItems={[
            "番号だけ必要です。もし十人以上がいれば、他の情報部分に正確な数を書いて下さい。",
          ]}
        />
        <TipSubSect
          listTitle="配偶者:"
          listItems={["結婚していますか。", "有る (Yes) か 無い (No)"]}
        />
        <TipSubSect
          listTitle="配偶者の扶養義務:"
          listItems={[
            "あなたの配偶者は働いていて、社会保険に加入していますか？ 無い (No)を選んでください。",
            "あなたの収入で配偶者を養うつもりですか？ 有る (Yes)を選んでください。",
          ]}
        />
        <TipsSection
          title="他の情報:"
          text="特に給料や職種、勤務時間や勤務地など、他の情報をここに入力して下さい。
          「特に無し」を書いても良いけど,
          ほとんどの方は　「貴社規定に従います」 を書きます。"
        />
      </div>
    </>
  );
};

export default JP3Tips;
