import React, { useContext } from "react";

import Header from "../Header/Header";

import ResumeStep1 from "./ResumeSteps/ResumeStep1";
import ResumeStep2 from "./ResumeSteps/ResumeStep2";
import ResumeStep3 from "./ResumeSteps/ResumeStep3";
import ResumeStep4 from "./ResumeSteps/ResumeStep4";
import ResumeStep5 from "./ResumeSteps/ResumeStep5";
import Form6 from "./ResumeForms/Form6/Form6";

import ResumeState from "./ResumeState/ResumeState";
import Form2State from "./ResumeState/Form2State";
import Form3State from "./ResumeState/Form3State";
import Form4To5State from "./ResumeState/Form4To5State";

import { AppContext } from "../../AppState/AppState";

import "../Resume/resume.css";

const Resume: React.FC = () => {
  const { step, language, USF } = useContext(AppContext);

  const headerTitleUS = [
    "Personal Information",
    "Education and Work History",
    "Certifications and Licenses",
    "Appeal Points",
    "Additional Information",
    "Preview, Save, and Print",
  ];
  const headerTitleJP = [
    "個人的な情報",
    "教育と仕事の歴史",
    "証明書と免許",
    "アピールポイント",
    "他の情報",
    "プレビュー・保存・プリント",
  ];

  let headerTitle = headerTitleUS;
  language === USF
    ? (headerTitle = headerTitleUS)
    : (headerTitle = headerTitleJP);

  const width = ["w-1/6", "w-1/3", "w-1/2", "w-2/3", "w-5/6", "w-full"];

  return (
    <>
      <Header headerTitle={headerTitle} width={width} />
      <ResumeState>
        <Form2State>
          <Form3State>
            <Form4To5State>
              {step === 1 ? <ResumeStep1 /> : ""}
              {step === 2 ? <ResumeStep2 /> : ""}
              {step === 3 ? <ResumeStep3 /> : ""}
              {step === 4 ? <ResumeStep4 /> : ""}
              {step === 5 ? <ResumeStep5 /> : ""}
              {step === 6 ? <Form6 /> : ""}
            </Form4To5State>
          </Form3State>
        </Form2State>
      </ResumeState>
      {/* <ResumeState>
          {step === 1 ? <Form1 /> : ""}
          <Form2State>
            {step === 2 ? <Form2 /> : ""}
            <Form3State>
              {step === 3 ? <Form3 /> : ""}
              <Form4To5State>
                {step === 4 ? <Form4 /> : ""}
                {step === 5 ? <Form5 /> : ""}
                {step === 6 ? <Form6 /> : ""}

                {!showPopup && step < 3 ? (
                  <ResumePage1 />
                ) : step < 3 ? (
                  <TipBox
                    content={
                      language === USF ? (
                        step < 2 ? (
                          <Form1Tips />
                        ) : (
                          <Form2Tips />
                        )
                      ) : step < 2 ? (
                        <JP1Tips />
                      ) : (
                        <JP2Tips />
                      )
                    }
                  />
                ) : (
                  ""
                )}
                {!showPopup && step >= 3 && step !== 6 ? (
                  <ResumePage2 />
                ) : step >= 3 && step < 6 ? (
                  <TipBox
                    content={language === USF ? <Form3Tips /> : <JP3Tips />}
                  />
                ) : (
                  ""
                )}
              </Form4To5State>
            </Form3State>
          </Form2State>
        </ResumeState> */}
    </>
  );
};

export default Resume;
