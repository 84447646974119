import React, { useContext } from "react";

import ProjectButton from "../../../../Buttons/ProjectButton";

import ProjectList from "../Form2Components/ProjectList";

import { ProjectsContext } from "../../../SkillSheetState/SSForm2State";
import { AppContext } from "../../../../../AppState/AppState";


const ProjectSect: React.FC = () => {
  const {language, USF} = useContext(AppContext);
    const {
        Projects,
      } = useContext(ProjectsContext);
  return (
    <section className="section mx-5">
      <ProjectList Projects={Projects} />
      <ProjectButton
        submit={true}
        classString="justify-center"
        text={language === USF ? "Add Project" : "プロジェクトを入れる"}
      />
    </section>
  );
};

export default ProjectSect;
