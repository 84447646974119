import React from "react";

interface Props {
  title: string;
  text: string;
}

const TipsSection: React.FC<Props> = ({ title, text }) => {
  return (
    <>
      <h2 className="tips-h2">{title}</h2>
      <p className="text-left">
        {text}
      </p>
    </>
  );
};

export default TipsSection;
