import React, { useContext } from "react";

import AddressBox from "../Page1SecParts/AddressBox";
import EmailBox from "../Page1SecParts/EmailBox";

import { ReducerContext } from "../../../ResumeState/ResumeState";

const ResumeContactInfo: React.FC = () => {
  
    const {
    //Form 1
    furiAddress,
    postal,
    address,
    email,
    homePhone,
    cellPhone,
    furiContactAddress,
    contactPostal,
    contactAddress,
    contactPhone,
    contactCellPhone,
    contactEmail,
  } = useContext(ReducerContext);

  return (
    <>
      {/* Start of Address Section */}
      <section className="resume-section">
        <AddressBox
          postal={postal}
          val={address}
          furival={furiAddress}
        />
        <EmailBox bottomb={false} email={email} homePhone={homePhone} cellPhone={cellPhone} />
      </section>
      {/* End of Address Section */}
      {/* Start of Contact Address Section */}
      <section className="resume-section mb-2">
        <AddressBox
          text="（現住所以外に連絡を希望する場合のみ記入）"
          postal={contactPostal}
          val={contactAddress}
          furival={furiContactAddress}
          bottomb={true}
        />
        <EmailBox
          email={contactEmail}
          homePhone={contactPhone}
          cellPhone={contactCellPhone}
          bottomb={true}
        />
      </section>
      {/* End of Contact Address Section */}
    </>
  );
};

export default ResumeContactInfo;
