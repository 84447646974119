import React from "react";

import SingleHistoryRow from "./SingleHistoryRow";

import "../../../../Resume/resume.css";

interface Props {
  startYear?: string;
  startMonth?: string;
  startText?: string;
  placeName?: string;
  inputs?: string;
  result?: string;
  endYear?: string;
  endMonth?: string;
  endText?: string;
  entry?: string;
  textRight?: boolean;
}

const HistoryInput: React.FC<Props> = ({
  startYear,
  startMonth,
  startText,
  placeName,
  inputs,
  result,
  entry,
  endYear,
  endMonth,
  endText,
  textRight,
}) => {
  return (
    <>
      {!startText || startText.length < 32 ? (
        <SingleHistoryRow
          year={startYear}
          month={startMonth}
          text={startText}
        />
      ) : (
        <>
          <SingleHistoryRow
            month={startMonth}
            year={startYear}
            text={placeName}
            entry={entry}
          />
          <SingleHistoryRow month={startMonth} year={startYear} text={inputs} />
        </>
      )}
      {!endText || endText.length < 32 ? (
        <SingleHistoryRow
          textRight={textRight}
          year={endYear}
          month={endMonth}
          text={endText}
        />
      ) : (
        <>
          <SingleHistoryRow year={endYear} month={endMonth} text={placeName} />
          <SingleHistoryRow
            year={endYear}
            month={endMonth}
            text={result}
          />
        </>
      )}
    </>
  );
};

export default HistoryInput;
