import React from "react";

import ResumeHeader from "../Page1/Page1Sections/ResumeHeader";
import PersonalInfo from "../Page1/Page1Sections/PersonalInfo";
import ResumeContactInfo from "../Page1/Page1Sections/ResumeContactInfo";

import ResumeHistory from "../Page1/Page1Sections/ResumeHistory";

import CertHistory from "../Page2/Page2Sections/CertHistory";
import AppealFamily from "../Page2/Page2Sections/AppealFamily";
import AdditionalInfo from "../Page2/Page2Sections/AdditionalInfo";

const PrintPages: React.FC = () => {
  return (
    <>
      <div className="print-wrapper grid place-content-center w-full">
        <div id="resume-print" className="print-page-div">
          <ResumeHeader />
          <PersonalInfo />
          <ResumeContactInfo />

          <ResumeHistory />

          <CertHistory />
          <AppealFamily />
          <AdditionalInfo />
        </div>
      </div>
    </>
  );
};

export default PrintPages;
