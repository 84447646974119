import React from "react";
import TipsSection from "../../TipBoxes/TipsSection";
import TipSubSect from "../../TipBoxes/TipSubSect";

const ProjectTB: React.FC = () => {
  return (
    <>
      <div className="tips-margin">
        <TipsSection
          title="Work Place:"
          text="What company did you work for when doing this project? You can use
          'Freelance' if there was no company."
        />
        <TipsSection
          title="Project Name:"
          text="What was the project's name?"
        />
        <TipsSection
          title="Start Date and End Date:"
          text=" Please fill out the start and end date. You must fill in each section
          including the day. If you do not know the exact day please guess."
        />
        <TipsSection
          title="Overview:"
          text="Provide a brief summary of the project and the end goal."
        />
        <TipsSection
          title="Points:"
          text="What were the main objectives of the project?"
        />
        <TipsSection
          title="Language/Environment:"
          text="List every computer language you used for the project and the
          environment such as the software or tech stack used. Type a language
          and click the Add Language Button. Remember to Click the 'Create List'
          Button when finished! The langauges will not appear in the project
          unless you click this button and see the langauges appear in the
          'Languages to be listed in Project' section."
        />
        <TipsSection
          title="Role/Position"
          text=" What was your job title? Member, Lead, Senior Developer, Junior
          Developer, etc."
        />
        <TipsSection title="Scale/Number of Personnel:" text="" />
        <TipSubSect
          listTitle="Number of Personnel:"
          listItems={["How many people worked with you during the project?"]}
        />
        <TipSubSect
          listTitle="Scale:"
          listItems={[
            "How many people worked on the project including people not in your team or from other companies? The number of people involved in the project.",
          ]}
        />
        <TipsSection title="Processes Followed" text="" />
        <TipSubSect
          listTitle="Requirements:"
          listItems={[
            "Did the project have a time line with objectives and due dates. Were there client oriented objectives?",
          ]}
        />
        <TipSubSect
          listTitle="Basic Design:"
          listItems={[
            "For the most part, all front end projects involve a basic design. Did your project include lines, drawings, shapes, fonts, or colors. These are part of your design.",
          ]}
        />
        <TipSubSect
          listTitle="Detailed Design:"
          listItems={[
            "This is more of a top down approach style in which you consider all technology, machines, and environments to be used. Then you work down into the fine details and other options for the project. This would probably be used for a large, sophisticated project.",
          ]}
        />
        <TipSubSect
          listTitle="Implementation:"
          listItems={[
            "This is one of the five phases of the project's life cycle. It involves following a plan to complete the project while achieving desired results.",
          ]}
        />
        <TipSubSect
          listTitle="Basic Test:"
          listItems={[
            "This involves testing each unit of a program to see if it works or not.",
          ]}
        />
        <TipSubSect
          listTitle="Conclusion Test:"
          listItems={[
            "This tests the program as a whole as all the integrated parts.",
          ]}
        />
        <TipSubSect
          listTitle="Maintenance:"
          listItems={[
            "Maintenace ensures the computer programs and systems remain operational and secure.",
          ]}
        />
      </div>
    </>
  );
};

export default ProjectTB;
