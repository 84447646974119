import React, { useContext } from "react";

import { Form4To5Context } from "../../../ResumeState/Form4To5State";

const Appeal: React.FC = () => {
  const { appealPoints } = useContext(Form4To5Context);

  return (
    <>
      {/* Appeal Points */}
      <tr className="block col-span-2">
        <td className="form-text-area-box block col-span-2 border-2 h-60">
          <p className="w-full px-3 border-b-2 border-double text-sm text-center">
            希望の動機・特技・興味・アピールポイントなど
          </p>
          <p className="m-2 text-xs">{appealPoints}</p>
        </td>
      </tr>
    </>
  );
};

export default Appeal;
