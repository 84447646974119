import React, { useContext } from "react";

import Appeal from "../Page2SecParts/Appeal";
import FullBox from "../Page2SecParts/FullBox";
import HalfBox from "../Page2SecParts/HalfBox";

import { Form4To5Context } from "../../../ResumeState/Form4To5State";
import TableHeader from "../../Page1/Page1SecParts/TableHeader";

const AppealFamily: React.FC = () => {
  const { travelHours, travelMinutes, dependents, spouse, spouseDependents } =
    useContext(Form4To5Context);

  return (
    <>
      {/* Appeal and Family Section */}
      <section className="mx-[5%]">
        <table className="w-full">
          <TableHeader padding="p-3" />
          <tbody className="grid grid-cols-3">
            <Appeal />
            <tr className="col-span-1">
              <td className="w-full block col-span-1 border-2 border-l-[0px] h-60">
                {/* Travel Time */}
                <FullBox
                  title="通勤時間"
                  content={`約 ${travelHours} 時間 ${travelMinutes} 分`}
                />
                {/* Family Section */}
                <FullBox
                  title="扶養家族数（配偶を除く）"
                  content={`${dependents} 人`}
                  extraClass="border-t-2"
                />
                <div className="w-full h-1/3 grid grid-cols-2 grid-rows-2 text-center text-xs border-t-2">
                  <HalfBox
                    title="配偶者"
                    variable={spouse}
                    extraClass="border-r-2"
                  />
                  <HalfBox
                    title="配偶者の扶養義務"
                    variable={spouseDependents}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </>
  );
};

export default AppealFamily;
