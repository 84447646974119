import React, { useContext } from "react";

import NextButton from "../../../Buttons/NextButton";
import BackButton from "../../../Buttons/BackButton";

import ProjectInfo from "./Form2Sections/ProjectInfo";
import LanguageSect from "./Form2Sections/LanguageSect";
import Environment from "./Form2Sections/Environment";
import ProjectSect from "./Form2Sections/ProjectSect";

import { ProjectsContext } from "../../SkillSheetState/SSForm2State";
import { AppContext } from "../../../../AppState/AppState";

const SSForm2: React.FC = () => {
  const { language, USF } = useContext(AppContext);

  const { projName, setProjects, setProjLanguagesClear, setProjectsClear } =
    useContext(ProjectsContext);

  const onProjectAdd = (e: React.FormEvent) => {
    e.preventDefault();
    if (projName) {
      setProjects(() => {});
      setProjectsClear();
      setProjLanguagesClear(() => {});
    }
  };

  const onSubmitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    onProjectAdd(e);
  };

  return (
    <>
      <form id="form" onSubmit={onSubmitHandler} className="w-full mb-7 h-fit">
        <h3 className="grid place-content-center mt-5 mx-5 text-lg text-purple">
          {language === USF
            ? "Click on the Add Project button when all fields are completed to include the project in the skill sheet."
            : "スキルツートにプロジェクトを含めるためにすべてのインプットを入力してからプロジェクトブトンを押して下さい。"}
        </h3>
        <div className="w-full md:flex flex-wrap justify-around grid">
          <ProjectInfo />
          <LanguageSect />
          <Environment />
        </div>
        <ProjectSect />
        <NextButton />
        <BackButton />
      </form>
    </>
  );
};

export default SSForm2;
