import React, { useContext } from 'react';

import SingleLanguage from './SingleLanguage';

import { Languages } from '../../../SkillSheetState/SSForm2State';
import { AppContext } from '../../../../../AppState/AppState';

interface Props {
    Languages: Languages[];
}

const LanguageList: React.FC<Props> = ({Languages}) => {
  const {language, USF} = useContext(AppContext)
    return (
        <>
        <div className="mt-4">
        <span className="text-lg text-purple">{language === USF ? "Language List" : "言語リスト"}</span>
        {
          Languages.map((item) => 
          (<SingleLanguage
            key={item.languageId}
            item={item}
            />))
        }
          </div>
        </>
      )
}

export default LanguageList