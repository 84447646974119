import React from "react";

import TipReferences from "../../TipBoxes/TipReferences";
import TipsSection from "../../TipBoxes/TipsSection";
import TipSubSect from "../../TipBoxes/TipSubSect";

const JPProjectTB: React.FC = () => {
  return (
    <>
      <div className="tips-margin">
      <TipReferences
          ref1="https://doda.jp/engineer/guide/qa/007.html"
          ref2="https://next.rikunabi.com/tenshokuknowhow/archives/22848/"
        />
        <TipsSection
          title="勤務先:"
          text="プロジェクトの時、会社なはなんでしょうか？ 義務先がなかったら、フリーランスを入力してもいいです。"
        />
        <TipsSection
          title="案件名:"
          text="プロジェクトの名前はなんでしょうか?"
        />
        <TipsSection
          title="開始日と終了日:"
          text="いつプロジェクトが始まりましたか？　いつプロジェクトが終りましたか？　年と月と日を入力して下さい。"
        />
        <TipsSection
          title="概要:"
          text="プロジェクトの簡単な要約と最終目標を入力して下さい。"
        />
        <TipsSection
          title="ポイント:"
          text="プロジェクトの主な目的を入力して下さい。"
        />
        <TipsSection
          title="言語・環境:"
          text="ここに各言語と環境を入力して下さい。言語か環境をタイプして「言語を入れる」
          ボタンをクリックして下さい。全ての言語と環境を入れてから、「リストを作る」ボタン
          をクリックして下さい。このボタンをクリックして、[プロジェクトに含まる言語] 
          セクションに言語が表示されるのを確認しない限り、言語はプロジェクトに表示されません。"
        />
        <TipsSection
          title="役割・役職："
          text="プロジェクトの時、役職はなんでしょうか？"
        />
        <TipsSection title="規模・人数:" text="" />
        <TipSubSect
          listTitle="人数:"
          listItems={["プロジェクトには何人が取り組みましたか?"]}
        />
        <TipSubSect
          listTitle="規模:"
          listItems={[
            "あなたのチームや他の会社の人を含めて、何人の人がプロジェクトに取り組みましたか? プロジェクトに参加した人数",
          ]}
        />
        <TipsSection title="担当工程" text="" />
        <TipSubSect
          listTitle="要件定義:"
          listItems={[
            "プロジェクトには、目標と期日を含むタイムラインがありましたか。クライアント指向の目標はありましたか?",
          ]}
        />
        <TipSubSect
          listTitle="基本設計:"
          listItems={[
            "ほとんどの場合、それぞれのフロントエンドプロジェクトには基本設計があります。色や形はデザインのために使いました。",
          ]}
        />
        <TipSubSect
          listTitle="詳細設計:"
          listItems={[
            "これは、すべてのテクノロジー、マシン、および環境を使用することを検討する、よりトップダウンのアプローチ スタイルです。 次に、プロジェクトの詳細とその他のオプションを検討します。 これは、大規模で洗練されたプロジェクトに使用される可能性があります。",
          ]}
        />
        <TipSubSect
          listTitle="実装:"
          listItems={[
            "これは、プロジェクトのライフ サイクルの 5 つのフェーズの 1 つです。 これには、計画に従ってプロジェクトを完了し、望ましい結果を達成することが含まれます。",
          ]}
        />
        <TipSubSect
          listTitle="単テスト:"
          listItems={[
            "これには、プログラムの各ユニットをテストして、機能するかどうかを確認することが含まれます。",
          ]}
        />
        <TipSubSect
          listTitle="結テスト:"
          listItems={[
            "これにより、統合されたすべての部分としてプログラム全体がテストされます。",
          ]}
        />
        <TipSubSect
          listTitle="保守運用:"
          listItems={[
            "メンテナンスは、コンピュータ プログラムとシステムが運用可能で安全であることを保証します。",
          ]}
        />
      </div>
    </>
  );
};

export default JPProjectTB;