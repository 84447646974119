import React from "react";

import TipsSection from "../../TipBoxes/TipsSection";
import TipSubSect from "../../TipBoxes/TipSubSect";

const SSMainTB: React.FC = () => {
  return (
    <>
      <div className="tips-margin">
        <h2 className="tip-step-subtitle">Step 1</h2>
        <TipsSection title="Company Name:" text="" />
        <TipSubSect
          listTitle="Name Furigana:"
          listItems={[
            "Use your full name in furigana.",
            "Use a translation service like Google Translate for the Hiragana if needed.",
            " Do not use Kanji or English for this section.",
          ]}
        />
        <TipSubSect
          listTitle="Name:"
          listItems={[
            "Use your full name in Japanese or English; however, Japanese is better.",
          ]}
        />
        <TipsSection
          title="Work Summary:"
          text="Provide a very brief job summary or description of what you did at
          your last or current company."
        />
        <TipsSection
          title="Knowledge and Experience:"
          text="Briefly describe the knowledge and experience you gained while working
          at this company."
        />
        <TipsSection
          title="Technology:"
          text="Describe the main technology you used at this company."
        />
        <TipsSection
          title="Other Information:"
          text="Enter any additional information that you believe is important for the
          reader to know."
        />
        <h2 className="tip-step-subtitle">Step 3</h2>
        <TipsSection
          title="PR:"
          text="Sell Yourself. What PR or important points and strengths about
          yourself do you think will help you get hired."
        />
      </div>
    </>
  );
};

export default SSMainTB;
