import React, { useContext } from "react";
import { Form4To5Context } from "../Resume/ResumeState/Form4To5State";

interface Props {
  divName: string;
  name: string;
  label: string;
  max: number;
  change: any;
  varOnly: any;
}

const NumberInput: React.FC<Props> = ({
  divName,
  max,
  name,
  label,
  change,
  varOnly,
}) => {
  const { setTravelMinutes } = useContext(Form4To5Context);
  return (
    <div className={divName}>
      <input
        aria-autoComplete
        className="drop-down shadow-lg"
        name={name}
        type="number"
        min="0"
        max={max}
        onChange={varOnly ? change : (e) =>
          change(
            change === setTravelMinutes
              ? e.target.value.length < 2
                ? "0" + e.target.value
                : e.target.value
              : e.target.value
          )
        }
      />
      <label className="grid place-content-center" htmlFor={name}>
        {label}
      </label>
    </div>
  );
};

export default NumberInput;
