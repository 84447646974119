import React from "react";

interface Props {
  padding: string;
}

const TableHeader: React.FC<Props> = ({padding}) => {
    // This was created for a buffer zone on page breaks because page margin is not working
  return (
    <thead className="opacity-0 no-break">
      <tr className="">
        <td className={padding}>Hello</td>
      </tr>
    </thead>
  );
};

export default TableHeader;
