import React from "react";

interface Props {
  postal: string;
  furival: string;
  val: string;
  text?: string;
  bottomb?: boolean;
}

const AddressBox: React.FC<Props> = ({ postal, furival, val, text, bottomb }) => {
  return (
    <table className="col-span-3">
      <tbody className={`col-span-3 ${bottomb ? "border-b-[2px]" : "border-b-[1px]"} border-l-2 border-r-2 border-t-[1px] h-28`}>
        <tr className="h-6 border-b-[1.5px] border-dashed">
          <td className="w-full flex px-3 text-xs">
            <span className="min-w-fit grid place-content-center">ふりがな</span> <span className="px-3 py-1">{furival}</span>
          </td>
        </tr>
        <tr className="grid my-1 max-h-22">
          <td className="flex mt-1 px-3 max-h-4">
            <p className="text-xs mr-1">現在住所</p>
            <span className="float-right w-2 text-xs">
              〒
            </span>
            
            <p className="text-xs pl-2">{postal}</p>
            <p className="text-xs pl-10">{text}</p>
          </td>
          <td><p className="px-3 py-2 text-sm">{val}</p></td>
        </tr>
      </tbody>
    </table>
  );
};

export default AddressBox;
