import React from "react";

import MiniTableContainer from "./MiniTableContainer";
import MiniTableRow from "./MiniTableRow";
import ProjectEnvironSect from "./ProjectEnvironSect";

interface Props {
  position?: string;
  numberOfPers?: string;
  scale?: string;
  requirements?: boolean;
  basicDesign?: boolean;
  detailedDesign?: boolean;
  implementation?: boolean;
  simpleTest?: boolean;
  conclusionTest?: boolean;
  maintenance?: boolean;
}

const ProjectEnvironment: React.FC<Props> = ({
  position,
  scale,
  numberOfPers,
  requirements,
  basicDesign,
  detailedDesign,
  implementation,
  simpleTest,
  conclusionTest,
  maintenance,
}) => {
    
  return (
    <>
      <tr className="grid col-span-3 h-64">
        <ProjectEnvironSect
          variable1={position}
          p2={false}
          label="役割・役職"
        />
        <ProjectEnvironSect
          variable1={`要員数：${numberOfPers}名`}
          variable2={`（PJ 全体：${scale}名）`}
          p2={true}
          label="規模・人数"
        />
        <td className="bg-skillsheetgray no-bottom-short-cell">担当工程</td>
        <MiniTableContainer bg={true}>
          <MiniTableRow text="要件定義" last={false} dot={false} />
          <MiniTableRow text="基本設計" last={false} dot={false} />
          <MiniTableRow text="詳細設計" last={false} dot={false} />
          <MiniTableRow text="実装" last={false} dot={false} />
          <MiniTableRow text="単テスト" last={false} dot={false} />
          <MiniTableRow text="結テスト" last={false} dot={false} />
          <MiniTableRow text="保守運用" last={true} dot={false} />
        </MiniTableContainer>
        <MiniTableContainer bg={false}>
          <MiniTableRow variable={requirements} last={false} dot={true} />
          <MiniTableRow variable={basicDesign} last={false} dot={true} />
          <MiniTableRow variable={detailedDesign} last={false} dot={true} />
          <MiniTableRow variable={implementation} last={false} dot={true} />
          <MiniTableRow variable={simpleTest} last={false} dot={true} />
          <MiniTableRow variable={conclusionTest} last={false} dot={true} />
          <MiniTableRow variable={maintenance} last={true} dot={true} />
        </MiniTableContainer>
      </tr>
    </>
  );
};

export default ProjectEnvironment;
