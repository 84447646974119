import React from "react";

import { ClipboardCopyIcon } from "@heroicons/react/solid";

interface Props {
  JPtext: string;
  ENtext: string;
}

const SuggestionList: React.FC<Props> = ({ JPtext, ENtext }) => {
  const copy = async (x: string) => {
    await navigator.clipboard.writeText(x);
  };

  return (
    <>
          <div className="flex pt-3">
            <p className="text-purple">{JPtext}</p>
            <ClipboardCopyIcon
              onClick={() => copy(`${JPtext}`)}
              className="text-orange hover:text-purple h-6 bg-white hover:bg-gray"
            />
          </div>
          <div className="">
            <p className="text-black flex">{ENtext}</p>
          </div>
    </>
  );
};

export default SuggestionList;
