import React from 'react'

import TipReferences from '../../TipBoxes/TipReferences'
import TipsSection from '../../TipBoxes/TipsSection'

const JP1Tips = () => {
  return (
    <>
    <div className="tips-margin">
      <TipReferences
        ref1={"https://doda.jp/guide/rireki/template/"}
      />
      <TipsSection
        title="写真:"
        text="写真の大きさは3cmX4cmでアップリは自動的にこの大きさを使います。
        男性は黒い背広と白いシャツとネックタイを着るべきでプロのように見えることは適当です。
        女性もプロのように見えることが必要で、化粧をしすぎないべきです。メガネをかけたり、
        帽子をかぶったり、顔を覆ったり、しないべきです。写真を作るためにフィグマや
        フォトショップのようなソフトがあります。このウェブサイトのおすすめÏは履歴書の写真
        のためにお金を支払わなければならないウェッブサイトかアプリを使いません。"
      />
      <TipsSection
        title="氏名:"
        text="姓氏・名前"
      />
      <TipsSection
        title="生年月日:"
        text="グレゴリオ暦を使って下さい。アプリは自動的に生年月日を和暦に変換します。"
      />
      <TipsSection
        title="性別:"
        text="性別を選んでください。答えはないを選ぶことはおすすめません。"
      />
      <TipsSection
        title="住所:"
        text="現在の住所。"
      />
      <TipsSection
        title="電子メール:"
        text="普通の電子メールを入力ください。プロイメージのように聞こうる電子メールはおすすめです。"
      />
      <TipsSection
        title="電話:"
        text="電話番号を入力して下さい。すカープ電話番号も使ってもいいです。"
      />
      <TipsSection
        title="他の連絡先:"
        text="他の連絡先を入力することが必要だとご存知だ方はこの部分を入力してもいいです。上の情報は同じだったら、「同上」か
        「どうじょう」。を入力してもいいです。"
      />
    </div>
  </>
  )
}

export default JP1Tips