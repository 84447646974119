import React, { useContext } from "react";

import FormInputWrapper from "../../../FormInputFields/FormInputWrapper";
import DataList from "../../../FormInputFields/DataList";
import StartEndDate from "../../../FormInputFields/StartEndDate";
import CertList from "./CertList";

import BackButton from "../../../Buttons/BackButton";
import NextButton from "../../../Buttons/NextButton";
import SmallCircleButton from "../../../Buttons/SmallCircleButton";

import { Form3Context } from "../../ResumeState/Form3State";
import { AppContext } from "../../../../AppState/AppState";

const Form3: React.FC = () => {
  const {language, USF} = useContext(AppContext);

  const onSubmitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    handleCertAdd(e);
  };

  const { certName, setCertName, setCertStartDate, certHist, setCertHist } =
    useContext(Form3Context);

  const handleCertAdd = (e: React.FormEvent) => {
    e.preventDefault();
    if (certName) {
      setCertHist(() => {});
      setCertName("");
      setCertStartDate("");
    }
  };

  const US = {
    certsLicTitle: "Certifications/Licenses",
    date: "Validation Date:",
    
  }
  const JP = {
    certsLicTitle: "証明書・免許",
    date: "有効日:",
  }

  let lang = US;
  language === USF ? (lang = US) : (lang = JP);

  return (
    <>
      <form className="w-full xl:w-1/2" onSubmit={onSubmitHandler}>
        <section className="section w-full">
          <FormInputWrapper label={lang.certsLicTitle}>
            <DataList
              value={certName}
              change={setCertName}
              max={30}
              varOnly={false}
              name="certifications"
              label={lang.certsLicTitle}
              listName="certandlic"
              optionList={[
                "普通自動車第一種運転免許",
                "日本語能力試験のN1 合格",
                "BJT ビジネス日本語能力テスト J1+",
                "TEFL Certificate",
              ]}
            />
            <StartEndDate
              label={lang.date}
              change={setCertStartDate}
              varOnly={false}
            />
          </FormInputWrapper>
          {certHist.length < 8 ? <SmallCircleButton /> : ""}
          <CertList certHist={certHist} />
        </section>
        <NextButton />
        <BackButton />
      </form>
    </>
  );
};

export default Form3;
