import React from "react";

interface Props {
  email: string;
  homePhone: string;
  cellPhone: string;
  bottomb: boolean;
}

const EmailBox: React.FC<Props> = ({ email, homePhone, cellPhone, bottomb }) => {
  return (
    <table className="col-span-1 border-t-[2px] border-r-2 mt-[-.85px]">
      <tbody className="col-span-1 min-h-28">
        <tr className="h-6">
          <td className={`w-full border-b-[1.5px] border-dashed pl-1 text-xs`}>
            電話<span className="pl-2">{homePhone}</span>
          </td>
        </tr>
        <tr className="">
          <td className="px-1 text-xs">
            携帯 <span className="pl-1">{cellPhone}</span>
          </td>
        </tr>
        <tr className={bottomb ? "border-b-2" :  ""}>
          <td className="text-xs border-t-2">
            <p className="px-2 text-xs">電子メール・E-mail</p>
            <p className="pb-[.125rem] px-2 py-1 text-[1em] break-all leading-3">
              {email}
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default EmailBox;
