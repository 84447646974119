import React from "react";

import TipReferences from "../../TipBoxes/TipReferences";
import TipsSection from "../../TipBoxes/TipsSection";

const Form1Tips: React.FC = () => {
  return (
    <>
      <div className="tips-margin">
        <TipReferences
          ref1={"https://blog.gaijinpot.com/write-japanese-resume"}
          ref2={
            "https://jobs.bfftokyo.com/ultimate-guide-to-writing-a-japanese-resume"
          }
        />
        <TipsSection
          title="Photo:"
          text="The photo should be 3cm X 4cm which the app does for you. Men should
          look professional in a black suit, white shirt, and tie. Women should
          also look professional and not wear excessive makeup. Do not wear a
          hat or cover your face with your hair or wear glasses. To size your
          photo you can use software like Photoshop, Figma, or web apps. There
          are websites where you pay for a resume photo but this is not
          recommended by this site."
        />
        <TipsSection
          title="Name:"
          text="In Japan the format for the name is: Last Name ・ First Name Middle
          Name. Notice that a comma is not used but a dot is. For the name in
          Furigana you can use Katakana and Google Translate. For the large name
          section you can use English characters for western names or Kanji."
        />
        <TipsSection
          title="Date of Birth:"
          text="Please fill out each section of the date. The date of birth is
          converted into the Japanese year and stating your age on the resume is
          normal in Japanese society. Japanese people will ask you how old you
          are and it is not impolite in their culture."
        />
        <TipsSection
          title="Gender:"
          text="Select your gender. Japanese society does not recognize genders
            outside male and female. They are not going to call you zih or zir."
        />
        <TipsSection
          title="Address:"
          text="This is your current addresss. Please fill out the Furigana address
          the best you can. Google Translate helps. It is also better to use as
          much Japanese as possible. Please be careful about the order of the
          address because it is different in Japan."
        />
        <TipsSection
          title="Email:"
          text=" Enter your normal Email address. It should sound professional."
        />
        <TipsSection
          title="Phone:"
          text="Please enter your phone number. If needed include country code. If it
          is a Japanese phone number you do not need the country code. You can
          also use a Skype phone number if needed."
        />
        <TipsSection
          title="Contact Information:"
          text=" Fill in this section if you want to include additional contact
          information. If it is the same as above you can just write 同上 or
          どうじょう."
        />
      </div>
    </>
  );
};

export default Form1Tips;
