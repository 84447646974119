import React from "react";

interface Props {
    fieldName: string;
}

const InputTitle: React.FC<Props> = ({ fieldName }) => {

  return (
    <div className="section-div">
      <legend className="h2">{fieldName}</legend>
    </div>
  );
};

export default InputTitle;
