import React from "react";

import SingleLanguageListItems from "./SingleLanguageListItems";

import { Languages } from "../../../SkillSheetState/SSForm2State";

interface Props {
  language: Languages[];
}

const SingleLanguageList: React.FC<Props> = ({ language }) => {
  return (
    <>
      {language.map(item => <SingleLanguageListItems key={item.languageId} language={item.languageEnv} />)}
    </>
  );
};

export default SingleLanguageList;
