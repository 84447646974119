import React from "react";
import InputTitle from "./InputTitle";

interface Props {
  label: string;
  classString?: string;
}

const FormInputWrapper: React.FC<Props> = ({ label, classString, children }) => {
  return (
    <>
    <fieldset className={`${classString}`}>
      <InputTitle fieldName={label} />
      <div className="input-div">{children}</div>
    </fieldset>
    </>
  );
};

export default FormInputWrapper;
