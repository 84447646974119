import React, { useContext } from "react";

import FormInputWrapper from "../../../FormInputFields/FormInputWrapper";
import InputTitle from "../../../FormInputFields/InputTitle";
import TextInput from "../../../FormInputFields/TextInput";
import EmailInput from "../../../FormInputFields/EmailInput";
import PhoneInput from "../../../FormInputFields/PhoneInput";
import RadioInput from "../../../FormInputFields/RadioInput";
import StartEndDate from "../../../FormInputFields/StartEndDate";

import NextButton from "../../../Buttons/NextButton";

import { ReducerContext } from "../../ResumeState/ResumeState";
import { AppContext } from "../../../../AppState/AppState";

const Form1: React.FC = () => {
  const {language, USF} = useContext(AppContext);

  const {
    pic,
    furiName,
    name,
    furiAddress,
    furiContactAddress,
    address,
    postal,
    contactPostal,
    contactAddress,
    setPic,
    setFuriName,
    setName,
    setDob,
    setFuriAddress,
    setPostal,
    setAddress,
    setEmail,
    setHomePhone,
    setCellPhone,
    setFuriContactAddress,
    setContactPostal,
    setContactAddress,
    setContactPhone,
    setContactCellPhone,
    setContactEmail,
    setMale,
    setFemale,
    setNull,
  } = useContext(ReducerContext);

  const US = {
    photo: "Photo (JPEG or PNG)",
    persInfo: "Personal Information",
    nameFuri: "Name (Furigana)",
    fullName: "Full Name",
    dob: "Date of Birth:",
    gender: "Gender",
    noAnswer: "No Answer",
    male: "Male",
    female: "Female",
    contactInfo: "Contact Information",
    pc: "Postal Code",
    addressFuri: "Address (Furigana)",
    address: "Address",
    home: "Home Phone",
    cell: "Cell Phone",
    email: "E-mail",
    altContact: "Additional Contact Information",
  }
  const JP = {
    photo: "写真（JPEG か PNG）",
    persInfo: "個人的な情報",
    nameFuri: "名前（ふりがな）",
    fullName: "名前",
    dob: "生年月日:",
    gender: "性別",
    noAnswer: "答えはない",
    male: "男",
    female: "女",
    contactInfo: "連絡先",
    pc: "郵便番号",
    addressFuri: "住所 (ふりがな)",
    address: "住所",
    home: "電話",
    cell: "携帯電話",
    email: "電子メール",
    altContact: "他の連絡先",
  }
  
  let lang = US;
  language === USF ? (lang = US) : (lang = JP);

  return (
    <>
      <form className="w-full xl:w-1/2 form md:grid-cols-2">
        {/* Photo Section */}
        <fieldset className="section md:h-[492px]">
          <InputTitle fieldName={lang.photo} />
          <div className="flex w-48 mb-5 flex-col justify-center">
            <img className="h-56 w-full" src={pic} alt="" />
            <input
              className="p-3 mt-1 text-xs button-animation button"
              id="Photo"
              type="file"
              placeholder={pic}
              onChange={setPic}
            />
          </div>
        </fieldset>
        {/* PI Section */}
        <FormInputWrapper
          classString="section"
          label={lang.persInfo}
        >
          <TextInput
            value={furiName}
            place="ほんだ・ゆうき"
            label={lang.nameFuri}
            name="NameFurigana"
            change={setFuriName}
            max={60}
            varOnly={true}
          />
          <TextInput
            value={name}
            label={lang.fullName}
            name="fullName"
            place="本田・勇気"
            change={setName}
            max={60}
            varOnly={true}
          />
        <StartEndDate
          label={lang.dob}
          change={setDob}
          varOnly={true}
        />
        <InputTitle fieldName={lang.gender} />
          <div className="grid">
            <RadioInput
              text={lang.noAnswer}
              change={setNull}
              name="gender"
              value="Null"
            />
            <RadioInput
              text={lang.male}
              change={setMale}
              name="gender"
              value="Male"
            />
            <RadioInput
              text={lang.female}
              change={setFemale}
              name="gender"
              value="Female"
            />
          </div>
        </FormInputWrapper>
        {/* Contact Information Section */}
        <FormInputWrapper
          classString="section"
          label={lang.contactInfo}
        >
          <TextInput
            max={10}
            value={postal}
            label={lang.pc}
            name="postalCode"
            place="596-4447"
            change={setPostal}
            varOnly={true}
          />
          <TextInput
            max={70}
            value={furiAddress}
            label={lang.addressFuri}
            name="AddressFurigana"
            place="とうきょうとみなとくあざぶだい"
            change={setFuriAddress}
            varOnly={true}
          />
          <TextInput
            max={70}
            value={address}
            label={lang.address}
            name="Address"
            place="東京都港区麻布台1丁目9番地12号"
            change={setAddress}
            varOnly={true}
          />
          <PhoneInput name="homePhone" label={lang.home} change={setHomePhone} />
          <PhoneInput name="cellPhone" label={lang.cell} change={setCellPhone} />
          <EmailInput name="email" label={lang.email} change={setEmail} />
        </FormInputWrapper>
        {/* Additional Contact Information Section */}
        <FormInputWrapper
          classString="section"
          label={lang.altContact}
        >
          <TextInput
            max={10}
            value={contactPostal}
            place="111-2222"
            label={lang.pc}
            name="altPostalCode"
            change={setContactPostal}
            varOnly={true}
          />
          <TextInput
            max={70}
            value={furiContactAddress}
            place="とうきょうとみなとくあざぶだい"
            label={lang.addressFuri}
            name="altAddressFurigana"
            change={setFuriContactAddress}
            varOnly={true}
          />
          <TextInput
            max={70}
            value={contactAddress}
            place="東京都港区麻布台1丁目9番地12号"
            label={lang.address}
            name="altAddress"
            change={setContactAddress}
            varOnly={true}
          />
          <PhoneInput name="altHomePhone" label={lang.home} change={setContactPhone} />
          <PhoneInput name="altCellPhone" label={lang.cell} change={setContactCellPhone} />
          <EmailInput label={lang.email} change={setContactEmail} />
        </FormInputWrapper>
        <NextButton />
      </form>
    </>
  );
};

export default Form1;
