import React, { useContext } from "react";

import InputTitle from "../../../FormInputFields/InputTitle";
import TextArea from "../../../FormInputFields/TextArea";
import AppealSuggestions from "./AppealSuggestions";
import SelectInput from "../../../FormInputFields/SelectInput";
import NumberInput from "../../../FormInputFields/NumberInput";

import NextButton from "../../../Buttons/NextButton";
import BackButton from "../../../Buttons/BackButton";

import { Form4To5Context } from "../../ResumeState/Form4To5State";
import { AppContext } from "../../../../AppState/AppState";

const Form4: React.FC = () => {
  const { language, USF } = useContext(AppContext);

  const {
    spouse,
    spouseDependents,
    setAppealPoints,
    setTravelHours,
    setTravelMinutes,
    setDependents,
    setSpouseTrue,
    setSpouseFalse,
    setSpouseDependentsTrue,
    setSpouseDependentsFalse,
  } = useContext(Form4To5Context);

  const onSubmitHandler = (e: React.FormEvent) => {
    e.preventDefault();
  };

  const US = {
    warning: "Please ensure that all the text appears in the box of the Resume",
    appeal: "Appeal Points",
    place:
      "Tell the reader about your hopes, special skills, interests, etc...",
    travel: "Travel Time",
    hours: "Hours",
    minutes: "Minutes",
    family: "Family",
    dependents: "Dependents (Not Including Spouse)",
    spouse: "Spouse",
    spouseDep: "Spouse's Dependents",
  };
  const JP = {
    warning: "全ての文章はアピールポイント箱にあることを確認して下さい。",
    appeal: "アピールポイント",
    place: "あなたの希望、特技、興味などについて読者に伝えてください。",
    travel: "通勤時間",
    hours: "時間",
    minutes: "分",
    family: "家族",
    dependents: "　扶養家族数（配偶を除く）　　　　",
    spouse: "配偶者",
    spouseDep: "配偶者の扶養義務",
  };

  let lang = US;
  language === USF ? (lang = US) : (lang = JP);

  return (
    <>
      <form className="w-full xl:w-1/2" onSubmit={onSubmitHandler}>
        <p className="p-3 text-center text-purple">{lang.warning}</p>
        <TextArea
          title={lang.appeal}
          label={lang.appeal}
          name="appeal"
          max={450}
          place={lang.place}
          change={setAppealPoints}
          varOnly={false}
          classString="text-area"
        />
        <AppealSuggestions />

        <fieldset className="section">
          <InputTitle fieldName={lang.travel} />
          <div className="select-drop-down-div">
            <NumberInput
              divName="time-div"
              name="hours"
              max={10}
              label={lang.hours}
              change={setTravelHours}
              varOnly={false}
            />
            <NumberInput
              divName="time-div"
              name="minutes"
              max={59}
              label={lang.minutes}
              change={setTravelMinutes}
              varOnly={false}
            />
          </div>
        </fieldset>

        <fieldset className="section">
          <InputTitle fieldName={lang.family} />
          <NumberInput
            divName="family-drop-down-div flex gap-3 p-3"
            name="dependents"
            max={10}
            label={lang.dependents}
            change={setDependents}
            varOnly={false}
          />
          <div className="md:flex gap-10 w-full p-3">
            <SelectInput
              label={lang.spouse}
              value={spouse}
              action1={setSpouseTrue}
              action2={setSpouseFalse}
            />
            <SelectInput
              label={lang.spouseDep}
              value={spouseDependents}
              action1={setSpouseDependentsTrue}
              action2={setSpouseDependentsFalse}
            />
          </div>
        </fieldset>
          <NextButton />
          <BackButton />
      </form>
    </>
  );
};

export default Form4;
