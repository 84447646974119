import React from "react";

interface Props {
  year?: string;
  month?: string;
  text?: string;
  textRight?: boolean;
  entry?: string;
}

const SingleHistoryRow: React.FC<Props> = ({ year, month, text, textRight, entry }) => {
  return (
    <>
      <tr className="no-break resume-history-div">
        <td className="resume-history-year-div">
          <p>{year}</p>
        </td>
        <td className="resume-history-month-div">
          <p>{month}</p>
        </td>
        <td className="resume-history-text-div">
          <p className={textRight ? "text-right" : ""}>{text}{entry}</p>
        </td>
      </tr>
    </>
  );
};

export default SingleHistoryRow;
