import React from "react";

interface Props {
    title: string;
    content: string;
    extraClass?: string;
}

const FullBox: React.FC<Props> = ({title, content, extraClass}) => {
  return (
    <>
      <div className={`h-1/3 ${extraClass}`}>
        <p className="w-full border-b-2 border-double text-sm text-center">
          {title}
        </p>
        <p className="grid items-center pr-4 text-xs text-right h-2/3">
          {content}
        </p>
      </div>
    </>
  );
};

export default FullBox;
