import React, { useContext } from "react";

import NextButton from "../../../Buttons/NextButton";
import BackButton from "../../../Buttons/BackButton";
import TextArea from "../../../FormInputFields/TextArea";

import { SkillSheetContext } from "../../SkillSheetState/SkillSheetState";
import { AppContext } from "../../../../AppState/AppState";

const SSForm3: React.FC = () => {
  const {language, USF} = useContext(AppContext);
  const { setPR } = useContext(SkillSheetContext);

  const onSubmitHandler = (e: React.FormEvent) => {
    e.preventDefault();
  };

  return (
    <>
      <form className="w-full mb-5 xl:w-1/2 h-fit" onSubmit={onSubmitHandler}>
        <TextArea
          title={language === USF ? "PR (Public Relations)" : "PR (広報)"}
          label="PR"
          name="PR"
          max={360}
          place={language === USF ? "Tell the reader about any other details you want them to know."
           : "読者に知ってもらいたいその他の詳細について説明して下さい。"}
          change={setPR}
          varOnly={true}
          classString="text-area"
        />
        <NextButton />
        <BackButton />
      </form>
    </>
  );
};

export default SSForm3;
