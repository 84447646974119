import React, { useContext, useEffect } from "react";

import HistoryTitle from "../Page1SecParts/HistoryTitle";
import HistoryInput from "../Page1SecParts/HistoryInput";
import SingleHistoryRow from "../Page1SecParts/SingleHistoryRow";

import { Form2Context } from "../../../ResumeState/Form2State";

const EducHistory: React.FC = () => {
  const { educHist, educChildren, setEducChildren } = useContext(Form2Context);
  useEffect(() => {
    let x = document.getElementById("educTable")?.childElementCount;
    if (x) {
      setEducChildren(x);
    }
  }, [educHist, setEducChildren]);

  return (
    <>
      <table className="w-full">
        <HistoryTitle
          year="年（西暦）"
          month="月"
          text="学歴・職歴"
          extraClass="border-t-[.5px]"
        />
        <HistoryTitle text="学歴" />
        <tbody id="educTable">
          {educHist.map((item) => (
            <HistoryInput
              key={item.educId}
              startText={`${item.schoolName} ${
                item.department ? item.department + "学部　" : ""
              } ${item.major ? item.major + "学科" : ""} ${
                item.schoolName ? "　入学" : ""
              }`}
              entry="　入学"
              placeName={item.schoolName}
              inputs={`${item.department ? item.department + "学部　" : ""} ${
                item.major ? item.major + "学科" : ""
              }`}
              startYear={item.educStartDate.slice(0, 4)}
              startMonth={item.educStartDate.slice(5, 7)}
              endText={`${item.schoolName}　 ${item.result}`}
              endYear={item.educEndDate.slice(0, 4)}
              endMonth={item.educEndDate.slice(5, 7)}
              result={item.result}
            />
          ))}
        </tbody>
        <tbody>
          {educHist.length < 1 ? <SingleHistoryRow /> : ""}
          {educChildren < 2 ? <SingleHistoryRow /> : ""}
          {educChildren < 3 ? <SingleHistoryRow /> : ""}
          {educChildren < 4 ? <SingleHistoryRow /> : ""}
          {educChildren < 5 ? <SingleHistoryRow /> : ""}
          {educChildren < 6 ? <SingleHistoryRow /> : ""}
        </tbody>
        <tfoot>
          <SingleHistoryRow />
        </tfoot>
      </table>
    </>
  );
};

export default EducHistory;
