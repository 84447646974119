import React, { useContext } from "react";

import FormInputWrapper from "../../../../FormInputFields/FormInputWrapper";
import TextInput from "../../../../FormInputFields/TextInput";
import ProjectButton from "../../../../Buttons/ProjectButton";

import LanguageList from "../Form2Components/LanguageList";
import SingleLanguageList from "../Form2Components/SingleLanguageList";

import { ProjectsContext } from "../../../SkillSheetState/SSForm2State";
import { AppContext } from "../../../../../AppState/AppState";

const LanguageSect: React.FC = () => {
  const {language, USF} = useContext(AppContext)
  const {
    languageEnv,
    languages,
    ProjLanguages,
    setLanguage,
    setLanguages,
    setLanguagesClear,
    setProjLanguages,
    setProjLanguagesClear,
  } = useContext(ProjectsContext);

  const onProjLanguagesClear = (e: React.FormEvent) => {
    e.preventDefault();
    if (languages) {
      setProjLanguagesClear(() => {});
    }
  };

  const onLanguageListAdd = (e: React.FormEvent) => {
    e.preventDefault();
    setProjLanguages(() => {});
  };

  const onLanguageAdd = (e: React.FormEvent) => {
    e.preventDefault();
    if (languageEnv) {
      setLanguages(() => {});
      setLanguagesClear();
    }
  };

  const US = {
    langEnv: "Language/Environment",
    instructions: 'Must click the "Create List" button after all items are added!',
    addLang: "Add Language",
    resetList: "Reset List",
    createList: "Create List",
    languageListLabel: "Languages to be listed in Project:",
    languageList: "Languages List",
  }

  const JP = {
    langEnv: "言語・環境",
    instructions: "全ての言語と環境が加えてから、「リストを作る」ボトンを押して下さい！",
    addLang: "言語を入れる",
    resetList: "リストを消す",
    createList: "リストを作る",
    languageListLabel: "プロジェクトに含まる言語",
    languageList: "言語リスト",
  }

  let lang = US;
  language === USF ? (lang = US) : (lang = JP)
  
  return (
    <section className="section mx-5">
      <FormInputWrapper label={lang.langEnv}>
        <TextInput
          max={1000}
          value={languageEnv}
          place="Javascript"
          label={lang.langEnv}
          change={setLanguage}
          varOnly={true}
        />
        <p className="text-red-500 pb-3">
         {lang.instructions}
        </p>
      </FormInputWrapper>
      <div className="flex justify-between">
        <ProjectButton
          submit={false}
          text={lang.addLang}
          action={onLanguageAdd}
        />
        <ProjectButton
          submit={false}
          text={lang.resetList}
          action={onProjLanguagesClear}
        />
        <ProjectButton
          submit={false}
          text={lang.createList}
          action={onLanguageListAdd}
        />
      </div>
      <span className="text-purple text-xl mt-8">
       {lang.languageListLabel}
        {ProjLanguages.map((language) => (
          <SingleLanguageList
            key={language.PLid}
            language={language.languages}
          />
        ))}
      </span>
      <LanguageList Languages={languages} />
    </section>
  );
};

export default LanguageSect;
