import React from "react";

interface Props {
  year?: string;
  month?: string;
  text?: string;
}

const CertHistInput: React.FC<Props> = ({ year, month, text }) => {
  return (
    <>
      <tr className="cert-history-div">
        <td className="resume-history-year-div">
          <p>{year}</p>
        </td>
        <td className="resume-history-month-div">
          <p>{month}</p>
        </td>
        <td className="resume-history-text-div">
          <p>{text}</p>
        </td>
      </tr>
    </>
  );
};

export default CertHistInput;
