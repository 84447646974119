import React, { useContext } from "react";

import TextArea from "../../../FormInputFields/TextArea";
import AdditionalInfoSuggestions from "./AdditionalInfoSuggestions";

import NextButton from "../../../Buttons/NextButton";
import BackButton from "../../../Buttons/BackButton";

import { Form4To5Context } from "../../ResumeState/Form4To5State";
import { AppContext } from "../../../../AppState/AppState";

const Form5: React.FC = () => {
  const { language, USF } = useContext(AppContext);

  const { setAdditionalInfo } = useContext(Form4To5Context);

  const onSubmitHandler = (e: React.FormEvent) => {
    e.preventDefault();
  };

  let text = "Additional Information";
  language === USF ? (text = "Additional Information") : (text = "他の情報");
  return (
    <>
      <form className="w-full xl:w-1/2" onSubmit={onSubmitHandler}>
        <TextArea
          label={text}
          title={text}
          name="information"
          max={575}
          place={
            language === USF
              ? "Tell the reader about any other details you want them to know such as desired salary, type of work, travel distance, work location, and any other requests etc..."
              : "希望する給与、仕事の種類、移動距離、勤務地、その他の要望など、読者に知ってもらいたいその他の詳細を伝えます。"
          }
          change={setAdditionalInfo}
          varOnly={false}
          classString="text-area"
        />
        <AdditionalInfoSuggestions />
        <NextButton />
        <BackButton />
      </form>
    </>
  );
};

export default Form5;
