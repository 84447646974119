import React from "react";

import SingleLanguageList from "../../Forms.tsx/Form2/Form2Components/SingleLanguageList";
import { ProjLanguages } from "../../SkillSheetState/SSForm2State";

interface Props {
  ProjLanguages?: ProjLanguages[];
}

const ProjLangSect: React.FC<Props> = ({ ProjLanguages }) => {
  return (
    <tr className="mid-cell text-xs">
      <td className="bg-skillsheetgray short-cell">
        環境・<span className="break-before">言語</span>
      </td>
      <td className="languages-cell text-sm min-h-[220px]">
        {ProjLanguages?.map((language) => (
          <SingleLanguageList
            key={language.PLid}
            language={language.languages}
          />
        ))}
      </td>
    </tr>
  );
};

export default ProjLangSect;
