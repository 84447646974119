import React from "react";

import SuggestionList from "../../../FormInputFields/SuggestionList";
import SuggestionListWrapper from "../../../FormInputFields/SuggestionListWrapper";

const AppealSuggestions: React.FC = () => {
  const SuggestionListText: [string, string][] = [
    [
      "給料：貴社の規定に従います。",
      "No particular preferences regarding salary.",
    ],
    ["給料：応相談と考えています。", "Salary is negotiable."],
    ["営業職を希望いたします。", "I would like a position in sales."],
    [
      "育児のため10時から17時までの事務を希望します。",
      "I would to work form 10AM to 5PM to care for my children.",
    ],
    [
      "18時以降の事務が難しくなっております。",
      "Working after 6PM is difficult. (Polite Form)",
    ],
    [
      "特になし。",
      "Nothing in particular. (This is a very common phrase.  You can just use this for the entire section.)",
    ],
  ];

  return (
    <>
      <SuggestionListWrapper>
        {SuggestionListText.map((strings, index) =>
          strings ? (
            <SuggestionList key={index} JPtext={strings[0]} ENtext={strings[1]} />
          ) : (
            ""
          )
        )}
      </SuggestionListWrapper>
    </>
  );
};

export default AppealSuggestions;
