import React from "react";

interface Props {
  title?: string;
  extras?: string;
  listTitle: string;
  listItems: string[];
}

const TipSubSect: React.FC<Props> = ({
  title,
  extras,
  listTitle,
  listItems,
}) => {
  return (
    <>
      <p className="mt-2">{title}</p>
      <p>{extras}</p>
      <ul>
        {listTitle}
        {listItems.map((listItem, index) => (
          <li key={index}>{listItem}</li>
        ))}
      </ul>
    </>
  );
};

export default TipSubSect;
