import React, { useContext } from "react";

import Form2 from "../ResumeForms/Form2/Form2";
import ResumePage1 from "../ResumePages/Page1/ResumePage1";

import TipBox from "../../TipBoxes/TipBox";
import Form2Tips from "../ResumeTipBoxes/Form2Tips";
import JP2Tips from "../ResumeTipBoxes/JP2Tips";

import { AppContext } from "../../../AppState/AppState";

const ResumeStep2: React.FC = () => {
  const { language, USF, showPopup } = useContext(AppContext);

  return (
    <>
      <div className="grid xl:flex justify-center">
          <Form2 />
          {!showPopup ? (
            <ResumePage1 />
          ) : (
            <TipBox content={language === USF ? <Form2Tips /> : <JP2Tips />} />
          )}
      </div>
    </>
  );
};

export default ResumeStep2;
