import React, { useContext } from "react";

import ResumeHeader from "./Page1Sections/ResumeHeader";
import PersonalInfo from "./Page1Sections/PersonalInfo";
import ResumeContactInfo from "./Page1Sections/ResumeContactInfo";
import ResumeHistory from "./Page1Sections/ResumeHistory";

import { AppContext } from "../../../../AppState/AppState";

const ResumePage1: React.FC = () => {
  const { step, language, USF } = useContext(AppContext);

  return (
    <>
      <section
        id="resume-page-1-bg"
        className={
          step > 1
            ? `hidden xl:w-[60%] w-full bg-gray bg-opacity-30 md:block h-full pb-3`
            : `block xl:w-1/2 h-full bg-gray bg-opacity-30`
        }
      >
        <h2 className="resume-bg-text">
        {language === USF ? "Resume Will Appear Differently When Printed" : "印刷すると履歴書の表示が異なります。"}
        </h2>
        <div
          id="resume-page-1"
          className={`page-div ${
            step > 1 ? `drop-shadow-lg w-[210mm]` : "w-[210mm] xl:w-[95%]"
          }`}
        >
          <ResumeHeader />
          <PersonalInfo />
          <ResumeContactInfo />
          <ResumeHistory />
        </div>
      </section>
    </>
  );
};

export default ResumePage1;
