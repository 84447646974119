import React from "react";

interface Props {
    projName?: string;
    overview?: string;
    points?: string;
}

const ProjInfoSect: React.FC<Props> = ({projName, overview, points}) => {
  return (
    <tr className="grid col-span-6">
      <td className="project-name-cell text-sm">{projName}</td>
      <td className="form-text-area-box grid grid-rows-2 overview-point-cell min-h-[220px]">
        <span className="row-span-1 grid m-1">
          【概要】<p className="text-sm">{overview}</p>
        </span>
        <span className="row-span-1 grid m-1">
          【ポイント】<p className="text-sm">{points}</p>
        </span>
      </td>
    </tr>
  );
};

export default ProjInfoSect;
