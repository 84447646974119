import React from 'react'

import TipReferences from '../../TipBoxes/TipReferences'
import TipsSection from '../../TipBoxes/TipsSection'
import TipSubSect from '../../TipBoxes/TipSubSect'

const JP2Tips = () => {
  return (
    <>
    <div className="tips-margin">
      <TipReferences
        ref1={"https://doda.jp/guide/rireki/template/"}
      />
      <TipsSection
        title="学歴:"
        text='全てのインプットを入力してオレンジ"＋"ボタンを押して下さい。
        最も早い学歴（普通に高校）から最も最近学歴まで、
        全ての学歴を入力して下さい。正式の学校名と学部と専攻を使って下さい。
        分からない場面があれば、大学の成績証明書を参照するの方がいいでしょう。
        学部と学科を入力することはありません。'
      />
      <TipSubSect
          title="結果:"
          listTitle="普通の結果は:"
          listItems={[
            "在学 (currently enrolled student)",
            "卒業 (graduated)",
          ]}
        />
      <TipsSection
        title="職歴:"
        text='全てのインプットを入力してオレンジ"＋"ボタンを押して下さい。
        最も早い職歴から最も最近職歴まで、全ての職歴を入力して下さい。
        仕事の詳細か仕事内容が必要はありません。職歴がなかったら、無しを入力してもいいです。'
      />
      <TipSubSect
          title="仕事を始める説明:"
          listTitle="普通の説明は:"
          listItems={[
            "入社 (joined the company)",
            "契約期間の初め (contract started)",
            "他の　(other)",
          ]}
        />      
      <TipSubSect
          title="仕事の引退する説明:"
          listTitle="普通の説明は:"
          listItems={[
            "現在に至る (Current company)",
            "一身上の都合により退職 (left for personal reasons)",
            "契約期間満了につき退社 (contract ended)",
            "会社都合により退社 (left for company reasons)",
            "結婚のために (Marriage)",
            "出産のために (Childbirth)",
            "家族の介護のため (Family care for parents, relatives, etc.)",
            "病院医療のため (Presently Working Here)",
          ]}
        />      
    </div>
  </>
  )
}

export default JP2Tips