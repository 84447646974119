import React, { useContext } from "react";
import SingleWorkItem from "./SingleWorkItem";

import { Form2Work } from "../../ResumeState/Form2State";
import { AppContext } from "../../../../AppState/AppState";

interface Props {
  workHist: Form2Work[];
}

const WorkList: React.FC<Props> = ({workHist}) => {
  const {language, USF} = useContext(AppContext);

  return (
    <>
    <div className="mt-4">
    <span className="text-xl text-purple">{language === USF ? "Work History List" : "職歴リスト"}</span>
    {
      workHist.map((item) => 
      (<SingleWorkItem
        key={item.workId}
        item={item}
        />))
    }
      </div>
    </>
  );
};

export default WorkList;
