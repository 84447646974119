import React, { useContext } from "react";
import SingleEducationItem from "./SingleEducationItem";

import { Form2Education } from "../../ResumeState/Form2State";
import { AppContext } from "../../../../AppState/AppState";

interface Props {
  educHist: Form2Education[];
}

const EducationList: React.FC<Props> = ({educHist}) => {
  const {language, USF} = useContext(AppContext);

  return (
    <>
    <div className="mt-4">
    <span className="text-purple text-xl">{language === USF ? "Education Background List" : "学歴リスト"}</span>
    {
      educHist.map((item) => 
      (<SingleEducationItem
        key={item.educId}
        item={item}
        />))
    }
      </div>
    </>
  );
};

export default EducationList;
